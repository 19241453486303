import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { IonLabel, IonTitle } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from '@carers/components/AppInformation/AppInformation.module.css';
import LogoMediflash from '@shared/assets/images/logo-mediflash-square-blue.svg';
import { PLATFORM } from '@shared/constants/appConstants';
import { LAST_COMMIT_HASH } from '@shared/environments/environment';

const AppInformation = () => {
  const { t } = useTranslation('app', { keyPrefix: 'shared' });

  const platform = Capacitor.getPlatform();
  const isWebPlatform = platform === PLATFORM.WEB;
  const [appVersion, setAppVersion] = useState<string | null>(null);

  useEffect(() => {
    const fetchAppInfo = async () => {
      if (!isWebPlatform) {
        const info = await App.getInfo();
        setAppVersion(info.version);

        return;
      }

      const lastCommitHash = LAST_COMMIT_HASH;
      setAppVersion(lastCommitHash.substring(0, 7));
    };

    fetchAppInfo().catch(() => {});
  }, [isWebPlatform]);

  return (
    <div className={`ion-text-center stack ${styles.footer}`}>
      <IonTitle className={styles.logo}>
        <img src={LogoMediflash} alt="" width={24} height={24} />
      </IonTitle>
      <div className={`${styles.appInfos} stack`}>
        <IonLabel className={styles.version}>{`${t('version')} : ${appVersion}`}</IonLabel>
        <IonLabel className={`${styles.baseline}`}>{t('baseline')}</IonLabel>
      </div>
    </div>
  );
};

export default AppInformation;
