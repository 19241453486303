const USERCENTRICS_WEB_SCRIPT_URL = 'https://web.cmp.usercentrics.eu/ui/loader.js';
const USERCENTRICS_SCRIPT_ID = 'usercentrics-cmp';
const USERCENTRICS_SETTINGS_ID = import.meta.env.VITE_USERCENTRICS_SETTINGS_ID;

const USERCENTRICS_CONSENT_EVENT_ID = 'UC_CONSENT';

let isUsercentricsInitialized = false;

declare global {
  interface Window {
    UC_UI: {
      showSecondLayer: () => void;
    };
  }
}

const insertScript = (script: HTMLScriptElement) => {
  const firstScript = document.getElementsByTagName('script')[0];

  if (!!firstScript) {
    firstScript.parentNode?.insertBefore(script, firstScript);

    return;
  }

  document.head.appendChild(script);
};

const getUsercentricsScript = () => {
  const script = document.createElement('script');
  script.async = true;
  script.src = USERCENTRICS_WEB_SCRIPT_URL;
  script.id = USERCENTRICS_SCRIPT_ID;
  script.setAttribute('data-settings-id', USERCENTRICS_SETTINGS_ID);

  return script;
};

const showConsentModalUI = () => {
  if (!!window.UC_UI) {
    window.UC_UI.showSecondLayer();
  }
};

type OnConsentChange = (consentStatus: string) => void;
interface ConsentDetail {
  consent: { status: string };
}

const init = (onConsentChange: OnConsentChange) => {
  if (!isUsercentricsInitialized) {
    window.addEventListener(USERCENTRICS_CONSENT_EVENT_ID, (event: Event) => {
      const consentEvent = event as CustomEvent<ConsentDetail>;
      onConsentChange(consentEvent.detail.consent.status);
    });

    const script = getUsercentricsScript();

    insertScript(script);

    isUsercentricsInitialized = true;
  }
};

export { init, showConsentModalUI, USERCENTRICS_SETTINGS_ID };
