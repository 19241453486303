type RetoolWidgetContainerProps = {
  width: number;
  retoolWidget: React.ReactNode;
};

const RetoolWidgetContainer = ({ width, retoolWidget }: RetoolWidgetContainerProps) => {
  return <div style={{ flex: `0 0 ${width}%` }}>{retoolWidget}</div>;
};

export default RetoolWidgetContainer;
