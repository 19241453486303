import { Capacitor } from '@capacitor/core';
import { IonContent, IonPage } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import { useTranslation } from 'react-i18next';

import AuthHeader from '@carers/views/Auth/components/AuthHeader';
import MinimumVersionError from '@shared/assets/images/status/minimum-version-error.svg';
import BlankSlate from '@shared/components/BlankSlate/BlankSlate';
import { PLATFORM } from '@shared/constants/appConstants';
import { STORE_LINK } from '@shared/constants/urlConstants';

const MinimumVersionBlocker = () => {
  const { t } = useTranslation('app', { keyPrefix: 'carers' });

  const redirectToStore = () => {
    const platform = Capacitor.getPlatform();

    if (platform === PLATFORM.WEB) {
      const error = new Error('Invalid platform for version blocker.');

      Sentry.captureException(error);

      throw error;
    }

    const storeUrl = platform === 'ios' ? STORE_LINK.IOS : STORE_LINK.ANDROID;

    window.location.href = storeUrl;
  };

  return (
    <IonPage>
      <AuthHeader />

      <IonContent>
        <BlankSlate
          action={redirectToStore}
          actionName={t('common.messages.errors.minimumVersion.button')}
          image={MinimumVersionError}
          message={t('common.messages.errors.minimumVersion.message')}
        />
      </IonContent>
    </IonPage>
  );
};

export default MinimumVersionBlocker;
