enum REQUESTS_FILTER_KEYS {
  CONTRACTS = 'contracts',
  RYTHMS = 'rythms',
  WITH_SCHEDULE_ON_OR_AFTER = 'withScheduleOnOrAfter',
}

type RequestsFilters = Partial<{
  [REQUESTS_FILTER_KEYS.CONTRACTS]: string[];
  [REQUESTS_FILTER_KEYS.RYTHMS]: string[];
  [REQUESTS_FILTER_KEYS.WITH_SCHEDULE_ON_OR_AFTER]: string;
}>;

export { type RequestsFilters, REQUESTS_FILTER_KEYS };
