import { IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import { PropsWithChildren } from 'react';

import { Logo, ShadeEnum } from '@shared/components/Logo/Logo';
import useIsScreenXL from '@shared/hooks/useIsScreenXL';

import HeaderWave from '../assets/header_wave.svg?react';

import styles from './AuthHeader.module.css';

const AuthHeader = ({ children }: PropsWithChildren) => {
  const isScreenXL = useIsScreenXL();

  if (isScreenXL) {
    return false;
  }

  return (
    <IonHeader className={styles.toolbarHeader}>
      <IonToolbar mode="ios">
        {children}
        <IonTitle>
          <Logo shade={ShadeEnum.light} />
        </IonTitle>
      </IonToolbar>
      <HeaderWave className={styles.headerWave} />
    </IonHeader>
  );
};

export default AuthHeader;
