import { Logo, ShadeEnum } from '@shared/components/Logo/Logo';

import styles from './XLScreenSideHeader.module.css';

const XLScreenSideHeader = () => (
  <div aria-hidden="true" className={styles.XLScreenHeader}>
    <Logo alt="none" shade={ShadeEnum.light} />
  </div>
);

export default XLScreenSideHeader;
