import { IonButton, IonLabel, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import { ModalTitle } from '@carers/components/Modal';

import styles from '../../Profile.module.css';

type UnsubscribeConfirmProps = {
  dismiss: () => Promise<void>;
  unsubscribe: () => void;
  isError: boolean;
  errorMessage?: string;
};

const UnsubscribeConfirmModal = ({
  dismiss,
  unsubscribe,
  isError,
  errorMessage,
}: UnsubscribeConfirmProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.profile' });

  return (
    <div className={`stack ${styles.safeArea}`}>
      <ModalTitle id="unsubscribe-modal-title">
        {t('messages.unsubscribe.unsubscribeConfirm')}
      </ModalTitle>
      <IonLabel>{t('messages.unsubscribe.unsubscribeConfirmText')}</IonLabel>
      {isError && <IonText color="danger">{errorMessage}</IonText>}
      <IonButton fill="outline" expand="block" onClick={unsubscribe}>
        {t('actions.unsubscribe')}
      </IonButton>
      <IonButton expand="block" onClick={dismiss}>
        {t('actions.cancel')}
      </IonButton>
    </div>
  );
};

export default UnsubscribeConfirmModal;
