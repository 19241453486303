import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonChip,
  IonContent,
  IonSkeletonText,
  IonText,
} from '@ionic/react';

import CardDetailSkeleton from '@carers/components/CardDetail/CardDetailSkeleton/CardDetailSkeleton';
import PageContentWithSidePanel from '@carers/components/PageContentWithSidePanel/PageContentWithSidePanel';
import RoundWaveContentHeader from '@carers/components/RoundWaveContentHeader/RoundWaveContentHeader';
import RequestAcceptanceSkeleton from '@carers/views/Requests/RequestDetail/RequestAcceptance/RequestAcceptanceSkeleton/RequestAcceptanceSkeleton';
import useIsScreenXL from '@shared/hooks/useIsScreenXL';

import styles from '../RequestDetail.module.css';

const RequestDetailSkeleton = () => {
  const isScreenXL = useIsScreenXL();

  return (
    <PageContentWithSidePanel sidePanel={<RequestAcceptanceSkeleton />}>
      <IonContent className="ion-padding withBlob">
        <RoundWaveContentHeader />
        <div className="stack">
          <IonCard>
            <IonCardHeader className={styles.ionCardHeader}>
              <IonChip disabled>
                <IonSkeletonText style={{ width: '100px' }} animated />
              </IonChip>
              <h5>
                <IonSkeletonText style={{ height: '20px', width: '100%' }} animated />
              </h5>
            </IonCardHeader>
            <IonCardContent>
              <IonText>
                <IonSkeletonText animated />
              </IonText>
            </IonCardContent>
            <div className="stack">
              <IonButton fill="clear" className="button-link" style={{ margin: 'auto' }}>
                <IonText aria-hidden="true" style={{ marginInlineEnd: '8px' }}>
                  <IonSkeletonText style={{ width: '150px' }} animated />
                </IonText>
              </IonButton>
            </div>
          </IonCard>
          <div className={`stack ${isScreenXL && 'stack-horizontal'}`}>
            <div className={`stack ${!isScreenXL && 'stack-horizontal'}`}>
              <CardDetailSkeleton />
              <CardDetailSkeleton />
            </div>
            <CardDetailSkeleton />
          </div>
          <IonCard>
            <IonCardHeader
              className={`${styles.ionCardHeader} stack stack-horizontal`}
              style={{ alignItems: 'center' }}
            >
              <h5>
                <IonSkeletonText style={{ height: '20px', width: '200px' }} animated />
              </h5>
            </IonCardHeader>
            <IonCardContent>
              <IonSkeletonText style={{ height: '200px', width: '100%' }} animated />
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </PageContentWithSidePanel>
  );
};

export default RequestDetailSkeleton;
