import { RefObject, useLayoutEffect, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';

import { delay } from '@shared/utils/utils-fn';

// Check if IonContent has overflow and can scroll
const useIsIonContentOverflow = (ref: RefObject<HTMLIonContentElement>) => {
  const [isOverflow, setIsOverflow] = useState(false);
  const { height } = useWindowSize();

  useLayoutEffect(() => {
    const trigger = async (ionContent: HTMLIonContentElement) => {
      const scrollElement = await ionContent.getScrollElement();
      await delay(100);
      const hasOverflow = scrollElement.scrollHeight > scrollElement.clientHeight;
      setIsOverflow(hasOverflow);
    };

    if (ref.current) {
      trigger(ref.current).catch(() => {});
    }
  }, [ref, height]);

  return isOverflow;
};

export default useIsIonContentOverflow;
