import { useEffect, useState } from 'react';

import { init } from '@shared/utils/ConsentManagement';

const useUsercentrics = () => {
  const [consent, setConsent] = useState<null | string>(null);

  const onConsentChange = (consentStatus: string) => {
    setConsent(consentStatus);
  };

  const initUsercentrics = async () => {
    init(onConsentChange);
  };

  useEffect(() => {
    initUsercentrics().catch((error) => {
      throw error;
    });
  }, []);

  return {
    consent,
  };
};

export default useUsercentrics;
