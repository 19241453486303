import { IonButton, IonContent, IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';

type SignContractProps = { onStartSigningSequence: () => void };

const SignContract = ({ onStartSigningSequence }: SignContractProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.missions.detail' });

  return (
    <IonContent className="ion-padding">
      <div className="stack stack-large">
        <h5>{t('sign.title')}</h5>
        <IonLabel>{t('sign.message')}</IonLabel>
        <IonButton onClick={onStartSigningSequence}>{t('actions.signContract')}</IonButton>
      </div>
    </IonContent>
  );
};

export default SignContract;
