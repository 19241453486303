import { IonCard, IonCardContent, IonSkeletonText } from '@ionic/react';

import styles from './UserTasks.module.css';

const UserTaskCardSkeleton = () => {
  return (
    <IonCard className={styles.cardTask}>
      <IonCardContent className={styles.cardTaskContent}>
        <IonSkeletonText style={{ width: '40px', height: '40px' }} />
        <IonSkeletonText animated style={{ width: '100px' }} />
        <IonSkeletonText animated style={{ width: '40px' }} />
      </IonCardContent>
    </IonCard>
  );
};

export default UserTaskCardSkeleton;
