enum MissionStatus {
  AWAITING_VALIDATION = 'AWAITING_VALIDATION',
  PENDING_SIGNATURE_REQUEST = 'PENDING_SIGNATURE_REQUEST',
  CONFIRMED = 'CONFIRMED',
  NOT_CONFIRMED_BY_FACILITY = 'NOT_CONFIRMED_BY_FACILITY',
  COMPLETED = 'COMPLETED',
  CANCELLED_BY_FACILITY = 'CANCELLED_BY_FACILITY',
  CANCELLED_BY_CARER_AND_JUSTIFIED = 'CANCELLED_BY_CARER_AND_JUSTIFIED',
  CANCELLED_BY_CARER_AND_NOT_JUSTIFIED = 'CANCELLED_BY_CARER_AND_NOT_JUSTIFIED',
  MISSION_SCHEDULES_AWAITING_VALIDATION = 'MISSION_SCHEDULES_AWAITING_VALIDATION',
}

export default MissionStatus;
