import {
  IonButton,
  IonCard,
  IonCardContent,
  IonInput,
  IonLabel,
  IonLoading,
  useIonToast,
} from '@ionic/react';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useSiretMask from '@carers/views/Profile/hooks/useSiretMask';
import {
  UserUpdateInformation,
  useUpdateProfessionalInformation,
} from '@carers/views/Profile/hooks/useUpdateProfessionalInformation';
import CheckIcon from '@shared/assets/icons/check-circle.svg';
import Alert from '@shared/components/Alert/Alert';

const ProfessionalInformationForm = () => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.profile' });
  const [presentToast] = useIonToast();
  const siretMask = useSiretMask();

  const [professionalInformation, setProfessionalInformation] = useState<UserUpdateInformation>();
  const {
    mutateAsync: professionalInformationMutation,
    isError,
    error,
    isPending,
  } = useUpdateProfessionalInformation();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    professionalInformationMutation(professionalInformation!)
      .then(async () => {
        await presentToast({
          message: t('messages.success.SIRETsent'),
          duration: 2000,
          position: 'bottom',
          color: 'success',
          icon: CheckIcon,
          positionAnchor: 'main-tab-bar',
        });
      })
      .catch(() => null);
  };

  const onIonInputChange = (event: Event) => {
    const target = event.target as HTMLInputElement;

    setProfessionalInformation({
      ...professionalInformation,
      siretId: target.value,
    });
  };

  return (
    <>
      <Alert type="warning" title={t('alerts.incompleteProfileTitle')}>
        <IonLabel>{t('alerts.incompleteProfileText')}</IonLabel>
      </Alert>

      <IonLoading isOpen={isPending} message={t('alerts.processingTitle')} />

      <IonCard>
        <IonCardContent className="stack">
          <p>{t('fillSIRET')}</p>
          <form onSubmit={handleSubmit} id="sendSiretForm" className="stack">
            <IonInput
              className={`${isError ? 'ion-invalid ion-touched' : ''}`}
              ref={async (siretInput) => {
                if (siretInput) {
                  const input = await siretInput.getInputElement();
                  siretMask(input);
                }
              }}
              inputmode="numeric"
              label={t('fillSIRETPlaceholder')}
              labelPlacement="floating"
              required
              fill="outline"
              errorText={isError ? error.errorMessage : undefined}
              onIonInput={(e) => onIonInputChange(e)}
            />
            <IonButton expand="block" type="submit" form="sendSiretForm">
              {t('actions.send')}
            </IonButton>
          </form>
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default ProfessionalInformationForm;
