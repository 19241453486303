import { IonFab, IonFabButton, IonIcon } from '@ionic/react';

import styles from '@carers/components/ScrollableContentButton/ScrollableContentButton.module.css';
import ArrowDown from '@shared/assets/icons/arrow_down.svg';

type ScrollableContentButtonProps = {
  ionContentRef: HTMLIonContentElement | null;
};

const ScrollableContentButton = ({ ionContentRef }: ScrollableContentButtonProps) => {
  return (
    <IonFab slot="fixed" horizontal="center" vertical="bottom" className={`${styles.fabScroll}`}>
      <IonFabButton
        onClick={async () => {
          await ionContentRef?.scrollToBottom(200);
        }}
        className={`${styles.fabScrollButton}`}
      >
        <IonIcon icon={ArrowDown} />
      </IonFabButton>
    </IonFab>
  );
};

export default ScrollableContentButton;
