import { useIonRouter } from '@ionic/react';
import { useCallback } from 'react';
import { useLocation } from 'react-router';

import usePageSearchParams from '@carers/views/Auth/hooks/usePageSearchParams';
import { isObjEmpty } from '@shared/utils/utils-fn';

const SEARCH_PARAM_FILTER_KEY = 'filters';

type GenericFilters = Record<string, string | string[]>;

function convertGenericFilterObjectToFiltersSearchParams(filters: GenericFilters) {
  if (Object.keys(filters).length === 0) {
    return new URLSearchParams();
  }

  const sortedFilters: GenericFilters = {};

  for (const [key, value] of Object.entries(filters)) {
    if (typeof value === 'string' && value.length !== 0) {
      sortedFilters[key] = value;
    } else if (Array.isArray(value) && value.length > 0) {
      sortedFilters[key] = value.sort();
    }
  }

  return isObjEmpty(sortedFilters)
    ? new URLSearchParams()
    : new URLSearchParams([[SEARCH_PARAM_FILTER_KEY, JSON.stringify(sortedFilters)]]);
}

function convertSearchParamsToObject(searchParams: URLSearchParams): GenericFilters {
  return searchParams.has(SEARCH_PARAM_FILTER_KEY)
    ? JSON.parse(searchParams.get(SEARCH_PARAM_FILTER_KEY)!)
    : {};
}

const useSearchParamFilter = <T extends GenericFilters>(baseUrl?: string) => {
  const searchParams = usePageSearchParams();
  const router = useIonRouter();
  const { pathname } = useLocation();

  const filters = convertSearchParamsToObject(searchParams) as T;

  const filtersCount = Object.keys(filters).length;
  const hasFilters = filtersCount > 0;

  const applyFilters = useCallback(
    (newFilters: GenericFilters) => {
      const params = convertGenericFilterObjectToFiltersSearchParams(newFilters);

      router.push(`${baseUrl ?? pathname}?${params.toString()}`, 'root', 'replace');
    },
    [baseUrl, pathname, router],
  );

  return { filters, filtersCount, hasFilters, applyFilters };
};

export { useSearchParamFilter, type GenericFilters };
