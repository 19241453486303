import { IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import styles from '@carers/components/AdditionalInformationCard/AdditionalInformation.module.css';
import MissionPropertyCardScaffold from '@carers/views/Missions/MissionDetail/components/Cards/MissionPropertyCardScaffold';
import { Mission } from '@carers/views/Missions/models/Missions';
import { Request } from '@carers/views/Requests/models/Requests';
import InfoIcon from '@shared/assets/icons/info.svg';

type AdditionalInformationCardProps = {
  request: Request | Mission;
};

const AdditionalInformationCard = ({ request: mission }: AdditionalInformationCardProps) => {
  const { t } = useTranslation('app', {
    keyPrefix: 'carers.requests.detail.additionalInformation',
  });

  const { additionalInformation, unit } = mission;

  const formatInformation = (propertyTitle: string, value: string) => {
    return (
      <div className="stack stack-no-space">
        <IonLabel color="primary" className={styles.propertyTitle}>
          {propertyTitle}
        </IonLabel>
        <IonLabel color="primary">{value}</IonLabel>
      </div>
    );
  };

  return (
    <MissionPropertyCardScaffold title={t('title')} icon={InfoIcon}>
      {!!unit && (
        <div
          className={
            !!additionalInformation ? `${styles.unitWithAdditionalInformation}` : undefined
          }
        >
          {formatInformation(t('unit'), unit)}
        </div>
      )}
      {!!additionalInformation && formatInformation(t('infos'), additionalInformation)}
    </MissionPropertyCardScaffold>
  );
};

export default AdditionalInformationCard;
