import { ImgHTMLAttributes } from 'react';

import LogoMediflash from '@shared/assets/images/icon-foreground.png';

type HeaderIconProps = ImgHTMLAttributes<HTMLImageElement>;

const HeaderIcon = ({ className, ...props }: HeaderIconProps) => {
  return (
    <img
      src={LogoMediflash}
      alt=""
      className={`ion-margin ion-hide-xl-up ${className || ''}`}
      width={24}
      height={24}
      {...props}
    />
  );
};

export default HeaderIcon;
