enum MISSIONS_FILTER_KEYS {
  IS_PAST = 'isPast',
  TASKS = 'tasks',
  CONTRACTS = 'contracts',
  RYTHMS = 'rythms',
  WITH_SCHEDULE_ON_OR_AFTER = 'withScheduleOnOrAfter',
}

type MissionsSideFilters = Partial<{
  [MISSIONS_FILTER_KEYS.TASKS]: string[];
  [MISSIONS_FILTER_KEYS.CONTRACTS]: string[];
  [MISSIONS_FILTER_KEYS.RYTHMS]: string[];
  [MISSIONS_FILTER_KEYS.WITH_SCHEDULE_ON_OR_AFTER]: string;
}>;

type MissionsInternalFilters = Partial<{
  [MISSIONS_FILTER_KEYS.IS_PAST]: string;
}>;

// Special keys: not include in filter count and not reset
const MISSIONS_FILTER_KEYS_INTERNAL: MISSIONS_FILTER_KEYS[] = [MISSIONS_FILTER_KEYS.IS_PAST];

type MissionsFilters = MissionsSideFilters & MissionsInternalFilters;

export {
  type MissionsFilters,
  type MissionsSideFilters,
  type MissionsInternalFilters,
  MISSIONS_FILTER_KEYS_INTERNAL,
  MISSIONS_FILTER_KEYS,
};
