import { useMaskito } from '@maskito/react';

const useSiretMask = () => {
  return useMaskito({
    options: {
      mask: Array(14).fill(/\d/),
    },
  });
};

export default useSiretMask;
