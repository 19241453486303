import { jwtDecode } from 'jwt-decode';

type JwtPayload = { sub: { userId: string } };

const getUserId = (accessToken: string) => {
  const decodedToken = jwtDecode<JwtPayload>(accessToken);

  const { userId } = decodedToken.sub;

  return userId;
};

export default getUserId;
