import LogoMediflashLinearDark from '@shared/assets/images/logo-mediflash-linear-dark.svg';
import LogoMediflashLinearLight from '@shared/assets/images/logo-mediflash-linear.svg';

enum ShadeEnum {
  light = 'light',
  dark = 'dark',
}

type LogoProps = { alt?: string; className?: string; shade: ShadeEnum };

const Logo = ({ alt, className, shade }: LogoProps) => {
  const src = shade === ShadeEnum.light ? LogoMediflashLinearLight : LogoMediflashLinearDark;

  return <img src={src} width="150" height="26" alt={alt} className={className} />;
};

export { Logo, ShadeEnum };
