import { GenericAbortSignal } from 'axios';

import { DayScheduleBase, MissionStandard } from '@carers/models/RequestAndMission';
import { MissionLight } from '@carers/views/Missions/models/Missions';
import { httpService } from '@shared/httpService';

type DayScheduleWithMissionIdDTO = {
  missionId: MissionStandard['id'];
} & DayScheduleBase;

type MissionsByMonthDTO = {
  daySchedules: Record<string, DayScheduleWithMissionIdDTO[]>;
  missions: Record<MissionStandard['id'], MissionLight>;
};

type MissionsByMonth = {
  daySchedules: Record<string, string[]>;
  missions: Record<string, MissionLight>;
};

const mapMissionsByMonthDTOToMissionsByMonth = ({
  missions,
  daySchedules,
}: MissionsByMonthDTO): MissionsByMonth => {
  const formattedDaySchedules: Record<string, MissionStandard['id'][]> = {};

  for (const [key, value] of Object.entries(daySchedules)) {
    formattedDaySchedules[key] = value.map((m) => m.missionId);
  }

  return {
    daySchedules: formattedDaySchedules,
    missions,
  };
};

const getMissionsByMonth = async (date: string, signal: GenericAbortSignal) => {
  const url = `/year-month/${date}/missions`;

  return httpService.get<MissionsByMonthDTO>(url, { signal }).then((response) => {
    return mapMissionsByMonthDTOToMissionsByMonth(response.data);
  });
};

export { getMissionsByMonth, type MissionsByMonth };
