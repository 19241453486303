import { AppRoute, RootRouteId } from '@shared/routes/route';

const authBasePath = '/auth';

const ROOT_ROUTES: AppRoute<RootRouteId>[] = [
  {
    id: 'login',
    path: `${authBasePath}/login`,
    label: 'carers.auth.login.title',
  },
  {
    id: 'otpCode',
    path: `${authBasePath}/otp-code`,
    label: 'carers.auth.OTP.title',
  },
];

export default ROOT_ROUTES;
