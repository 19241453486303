import { IonButton } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import ReportSchedulesProblem from '@carers/views/Missions/MissionDetail/components/ReportSchedulesProblem/ReportSchedulesProblem';
import SignContract from '@carers/views/Missions/MissionDetail/components/SignContract/SignContract';
import ValidateSchedules from '@carers/views/Missions/MissionDetail/components/ValidateSchedules/ValidateSchedules';
import { Mission } from '@carers/views/Missions/models/Missions';

import styles from '../../MissionDetail.module.css';

type ActionsPanelProps = {
  dismissReportModal: () => Promise<void>;
  dismissValidateModal: () => Promise<void>;
  mission: Mission | undefined;
  onStartSigningSequence: () => void;
  setShowReportScheduleProblem: (value: boolean) => void;
  setShowValidateSchedules: (value: boolean) => void;
  shouldShowValidateSchedulesButton: boolean;
  shouldShowReportSchedulesButton: boolean;
  shouldShowValidateSchedules: boolean;
  shouldShowReportScheduleProblem: boolean;
};

const ActionsPanel = ({
  dismissReportModal,
  dismissValidateModal,
  mission,
  onStartSigningSequence,
  setShowReportScheduleProblem,
  setShowValidateSchedules,
  shouldShowValidateSchedulesButton,
  shouldShowReportSchedulesButton,
  shouldShowValidateSchedules,
  shouldShowReportScheduleProblem,
}: ActionsPanelProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.missions.detail' });

  const onReportProblemButtonClick = () => {
    setShowReportScheduleProblem(true);
  };

  const onValidateSchedulesButtonClick = () => {
    setShowValidateSchedules(true);
  };

  const isContractNotSigned = !!mission?.contractSignatureUrl;

  if (isContractNotSigned) {
    return <SignContract onStartSigningSequence={onStartSigningSequence} />;
  }

  if (mission && shouldShowReportScheduleProblem) {
    return (
      <ReportSchedulesProblem
        dismissModal={dismissReportModal}
        mission={mission}
        setShowReportSchedulesProblem={setShowReportScheduleProblem}
      />
    );
  }

  if (mission && shouldShowValidateSchedules) {
    return (
      <ValidateSchedules
        dismissModal={dismissValidateModal}
        mission={mission}
        setShowValidateSchedules={setShowValidateSchedules}
      />
    );
  }

  return (
    <div className="stack ion-padding">
      <h2 className={styles.sideTitle}>{t('expectedActions')}</h2>
      {shouldShowReportSchedulesButton && (
        <IonButton
          expand="block"
          fill="outline"
          onClick={onReportProblemButtonClick}
          className="ion-margin-bottom"
        >
          {t('actions.reportProblem')}
        </IonButton>
      )}
      {shouldShowValidateSchedulesButton && (
        <IonButton expand="block" onClick={onValidateSchedulesButtonClick}>
          {t('actions.validateSchedules')}
        </IonButton>
      )}
    </div>
  );
};

export default ActionsPanel;
