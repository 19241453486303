import { IonContent, IonHeader, IonLabel, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import Alert from '@shared/components/Alert/Alert';
import { Logo, ShadeEnum } from '@shared/components/Logo/Logo';

const NoFacilityErrorPage = () => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="ion-hide-xl-up ion-text-center">
            <Logo alt={t('common.menu.home')} shade={ShadeEnum.light} />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <Alert type="danger" title={t('facilities.errors.noFacilityFound.title')}>
          <IonLabel>{t('facilities.errors.noFacilityFound.subtitle')}</IonLabel>
        </Alert>
      </IonContent>
    </IonPage>
  );
};

export default NoFacilityErrorPage;
