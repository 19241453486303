import { IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import MissionScheduleDates from '@carers/components/MissionScheduleDates/MissionScheduleDates';
import { DATE_CONFIG, useFormatDate } from '@carers/hooks/useFormatDate';
import MissionPropertyCardScaffold from '@carers/views/Missions/MissionDetail/components/Cards/MissionPropertyCardScaffold';
import { Mission } from '@carers/views/Missions/models/Missions';
import TimeIcon from '@shared/assets/icons/time.svg';

import styles from '../../MissionDetail.module.css';

type MissionScheduleCardProps = {
  mission: Mission;
};

const MissionScheduleCard = ({ mission }: MissionScheduleCardProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.missions.detail' });
  const { formatDate } = useFormatDate();

  const { schedulesValidatedAt, schedulesProblemReportedAt } = mission;

  const shouldDisplaySchedulesValidatedAt = !!schedulesValidatedAt;
  const shouldDisplaySchedulesProblemReportedAt = !!schedulesProblemReportedAt;
  const shouldDisplaySchedulesValidatedAtAndProblemReportedAt =
    shouldDisplaySchedulesValidatedAt && shouldDisplaySchedulesProblemReportedAt;

  const formatSchedulesValidationInfo = (text: string, date: string) => {
    return (
      <div className="stack stack-no-space">
        <IonLabel color="primary">{text}</IonLabel>
        <IonLabel color="primary" className={styles.typoDate}>
          {formatDate(date, DATE_CONFIG.FULL_DATE_WITH_TIME)}
        </IonLabel>
      </div>
    );
  };

  return (
    <MissionPropertyCardScaffold title={t('schedule')} icon={TimeIcon}>
      <MissionScheduleDates daySchedules={mission.daySchedules} />
      {shouldDisplaySchedulesProblemReportedAt && (
        <div
          className={
            shouldDisplaySchedulesValidatedAtAndProblemReportedAt
              ? `${styles.schedulesProblemReportedAt}`
              : undefined
          }
        >
          {formatSchedulesValidationInfo(
            t('schedulesValidation.schedulesProblemReported'),
            schedulesProblemReportedAt,
          )}
        </div>
      )}
      {shouldDisplaySchedulesValidatedAt &&
        formatSchedulesValidationInfo(
          t('schedulesValidation.schedulesValidated'),
          schedulesValidatedAt,
        )}
    </MissionPropertyCardScaffold>
  );
};

export default MissionScheduleCard;
