import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/capacitor';
import axios, { type AxiosInstance, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';

import { PLATFORM as CAPACITOR_PLATFORM } from '@shared/constants/appConstants';
import { API_BASE_URL } from '@shared/environments/environment';

type CustomAxiosRequestConfig = {
  validationErrorMessage?: string;
} & AxiosRequestConfig;

enum MinimumVersionHeader {
  PLATFORM = 'X-App-Platform',
  VERSION = 'X-App-Version',
}

const MINIMUM_VERSION_ERROR_CODE = 'APP_VERSION_OLDER_THAN_MINIMUM_ALLOWED';

const httpService: AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30_000,
});

httpService.defaults.headers.common = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
};

const attachAppVersionAndPlatformToHeaders = async (config: InternalAxiosRequestConfig) => {
  try {
    const updatedConfig = { ...config };

    const platform = Capacitor.getPlatform();

    updatedConfig.headers[MinimumVersionHeader.PLATFORM] = platform;

    // Note(@nbdn): App.getInfo is not supported on web platform
    // so early return here
    if (platform === CAPACITOR_PLATFORM.WEB) {
      return updatedConfig;
    }

    const { version } = await App.getInfo();

    updatedConfig.headers[MinimumVersionHeader.VERSION] = version;

    return updatedConfig;
  } catch (error) {
    Sentry.captureException(error);

    return config;
  }
};

httpService.interceptors.request.use(attachAppVersionAndPlatformToHeaders);

const setupAppMinimumVersionInterceptor = (triggerUpdateRequired: () => void) => {
  httpService.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.data?.error?.code === MINIMUM_VERSION_ERROR_CODE) {
        triggerUpdateRequired();
      }

      return Promise.reject(error);
    },
  );
};

export { httpService, setupAppMinimumVersionInterceptor, type CustomAxiosRequestConfig };
