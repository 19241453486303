import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonChip,
  IonItem,
  IonLabel,
  IonList,
  IonSkeletonText,
} from '@ionic/react';
import { useMemo } from 'react';

import { generateArrayOfRandomNumbers } from '@shared/utils/utils-fn';

import styles from './CardMission.module.css';

const CardMissionSkeleton = () => {
  const randomItems = useMemo(() => generateArrayOfRandomNumbers(5), []);

  return (
    <IonCard>
      <IonCardHeader className={styles.ionCardHeader}>
        <IonChip style={{ width: '100px' }} disabled>
          <IonSkeletonText animated />
        </IonChip>
        <h5>
          <IonSkeletonText style={{ height: '20px', width: '100%' }} animated />
        </h5>
      </IonCardHeader>
      <IonCardContent>
        <IonList className={styles.ionList}>
          {randomItems.map((item) => (
            <IonItem key={item} className="ion-no-margin ion-no-padding" lines="none">
              <IonLabel className="ion-no-margin">
                <IonSkeletonText style={{ width: '350px' }} animated />
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
        <IonButton fill="outline" expand="block" disabled>
          <IonSkeletonText animated style={{ width: '50%' }} />
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};

export default CardMissionSkeleton;
