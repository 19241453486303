import { IonButton, IonLabel } from '@ionic/react';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import ModalTitle from '@carers/components/Modal/ModalTitle';
import { AuthMode } from '@carers/views/Auth/AuthMode';
import { OTPTypeCode } from '@carers/views/Auth/OTPCode/OTPCodeModal';
import Alert from '@shared/components/Alert/Alert';
import { GenericAxiosResponseError } from '@shared/HttpInterceptors/GenericAxiosResponseError';

type CodeNotReceivedProps = {
  loginMode: AuthMode;
  loginValue: string;
  switchAuthMethod: () => void;
  sendNewRequestCode: () => void;
  nextRetryAllowedAt?: Date;
  error: GenericAxiosResponseError | null;
  isError: boolean;
};

const REGISTER_LINK = 'https://mediflash.fr/inscription-soignants/';

// From now
const getDistanceToNowInSeconds = (date: Date) => {
  return Math.round((date.getTime() - Date.now()) / 1000);
};

const CodeNotReceived = ({
  loginMode,
  loginValue,
  switchAuthMethod,
  sendNewRequestCode,
  nextRetryAllowedAt,
  error,
  isError,
}: CodeNotReceivedProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.auth.OTP' });
  const [, setNow] = useState(new Date());

  const OTPTypeValues = OTPTypeCode[loginMode];

  const canRequestNewCode = nextRetryAllowedAt
    ? getDistanceToNowInSeconds(nextRetryAllowedAt) <= 0
    : true;

  useEffect(() => {
    if (nextRetryAllowedAt) {
      const interval = setInterval(() => {
        if (canRequestNewCode) {
          clearInterval(interval);
        } else {
          setNow(new Date());
        }
      }, 1000);

      return () => clearInterval(interval);
    }

    return undefined;
  }, [nextRetryAllowedAt, canRequestNewCode]);

  return (
    <div className="ion-padding-horizontal ion-padding-bottom stack">
      <ModalTitle id="code-not-received-title">{t(OTPTypeValues.title)}</ModalTitle>
      <IonLabel>
        <Trans
          shouldUnescape
          i18nKey={t('codeNotReceivedModal.checkParams')}
          values={{ mode: t(OTPTypeValues.checkLoginMode), loginValue }}
        />
      </IonLabel>
      <IonButton fill="clear" className="button-link" onClick={switchAuthMethod}>
        {t(OTPTypeValues.switchText)}
      </IonButton>
      {!canRequestNewCode && (
        <IonLabel className="ion-text-center">
          {t('codeNotReceivedModal.newCodeSend', {
            typeCode: t(OTPTypeValues.name),
            timeLeft: getDistanceToNowInSeconds(nextRetryAllowedAt!),
            formatParams: { timeLeft: { style: 'short', numeric: 'always' } },
            interpolation: { escapeValue: false },
          })}
        </IonLabel>
      )}
      {isError && <Alert type="danger">{error?.errorMessage}</Alert>}
      <IonButton fill="outline" onClick={sendNewRequestCode} disabled={!canRequestNewCode}>
        {t('codeNotReceivedModal.actions.receiveCodeAgain', {
          typeCode: t(OTPTypeValues.name),
          interpolation: { escapeValue: false },
        })}
      </IonButton>
      <IonButton href={REGISTER_LINK} target="_blank">
        {t('codeNotReceivedModal.actions.register')}
      </IonButton>
    </div>
  );
};

export default CodeNotReceived;
