import { useInfiniteQuery } from '@tanstack/react-query';

import { MissionsFilters } from '@carers/views/Missions/models/MissionsFilters';
import missionQueries from '@carers/views/Missions/utils/missionQueryFactory';

const useGetMissions = (filters: MissionsFilters) => {
  const newFilters = { ...filters, isPast: !filters.isPast ? 'false' : filters.isPast };

  return useInfiniteQuery(missionQueries.list(newFilters));
};

export default useGetMissions;
