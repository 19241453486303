import { MaskitoOptions } from '@maskito/core';
import { maskitoAddOnFocusPlugin, maskitoRemoveOnBlurPlugin } from '@maskito/kit';
import { useMaskito } from '@maskito/react';

const frenchMobilePrefix = '+33';

const phoneMaskOptions: MaskitoOptions = {
  mask: [
    ...frenchMobilePrefix.split(''),
    ' ',
    /6|7/,
    ' ',
    ...Array(2).fill(/\d/),
    ' ',
    ...Array(2).fill(/\d/),
    ' ',
    ...Array(2).fill(/\d/),
    ' ',
    ...Array(2).fill(/\d/),
  ],
  plugins: [
    maskitoAddOnFocusPlugin(frenchMobilePrefix),
    maskitoRemoveOnBlurPlugin(frenchMobilePrefix),
  ],
};

const usePhoneMask = () => {
  return useMaskito({
    options: phoneMaskOptions,
  });
};

export default usePhoneMask;
