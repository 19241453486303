import { ReactElement } from 'react';

import useAuthTokenInterceptor from '@shared/HttpInterceptors/useAuthTokenInterceptor';
import useHandleErrorMessageInterceptor from '@shared/HttpInterceptors/useHandleErrorMessageInterceptor';

const HttpInterceptors = (): ReactElement | null => {
  useAuthTokenInterceptor();
  useHandleErrorMessageInterceptor();

  return null;
};

export default HttpInterceptors;
