import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { MissionStandard } from '@carers/models/RequestAndMission';
import missionQueries from '@carers/views/Missions/utils/missionQueryFactory';
import requestQueries from '@carers/views/Requests/utils/requestQueryFactory';
import useIsScreenXL from '@shared/hooks/useIsScreenXL';
import { httpService } from '@shared/httpService';

type AcceptSchedulesDTO = {
  requestId: MissionStandard['id'];
  dateSchedulesIds: string[];
};

const postAcceptSchedules = async ({ requestId, dateSchedulesIds }: AcceptSchedulesDTO) => {
  const url = `/requests/${requestId}/accept-schedules`;

  return httpService
    .post<{ missionId: string }>(url, { dayScheduleIds: dateSchedulesIds })
    .then((response) => response.data.missionId);
};

const useAcceptSchedules = () => {
  const queryClient = useQueryClient();
  const isScreenXL = useIsScreenXL();

  return useMutation({
    mutationFn: postAcceptSchedules,
    onSuccess() {
      queryClient.removeQueries({ queryKey: missionQueries.all() });
    },
    onSettled(data, error, variables) {
      if (error?.code === AxiosError.ERR_NETWORK) {
        return null;
      }

      return Promise.all([
        ...(isScreenXL
          ? []
          : [
              queryClient.invalidateQueries({
                queryKey: requestQueries.detail(variables.requestId).queryKey,
              }),
            ]),
        queryClient.invalidateQueries({ queryKey: requestQueries.lists(), refetchType: 'all' }),
      ]);
    },
  });
};

export default useAcceptSchedules;
