function delay(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const isObjEmpty = (obj: Record<string, unknown>) => {
  return Object.keys(obj).length === 0;
};

const generateArrayOfRandomNumbers = (length: number) =>
  Array.from({ length }, () => Math.floor(Math.random() * 1_000_000) + 1);

const setDocumentMetaTag = (name: string, content: string) => {
  const metaTag = document.querySelector<HTMLMetaElement>(`meta[name=${name}]`);

  if (metaTag) {
    metaTag.setAttribute('content', content);
  } else {
    const newMetaTag = document.createElement('meta');
    newMetaTag.setAttribute('name', name);
    newMetaTag.setAttribute('content', content);
    document.getElementsByTagName('head')[0].appendChild(newMetaTag);
  }
};

export { delay, generateArrayOfRandomNumbers, isObjEmpty, setDocumentMetaTag as setMetaTag };
