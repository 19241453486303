import { Mission } from '@carers/views/Missions/models/Missions';
import MissionStatus from '@carers/views/Missions/models/MissionStatus';
import { CONTRACTS } from '@shared/constants/missionsConstants';

const doesMissionNeedActions = (mission?: Mission) => {
  if (!mission) {
    return false;
  }

  const isShortTermContract = mission.contract.value === CONTRACTS.SHORT_TERM;

  if (isShortTermContract) {
    return false;
  }

  const isContractSigned = !mission.contractSignatureUrl;

  if (!isContractSigned) {
    return true;
  }

  const isMissionConfirmed = mission.status.value === MissionStatus.CONFIRMED;
  const isAwaitingSchedulesValidation =
    mission.status.value === MissionStatus.MISSION_SCHEDULES_AWAITING_VALIDATION;

  if (!isMissionConfirmed && !isAwaitingSchedulesValidation) {
    return false;
  }

  const wasSchedulesProblemReported = !!mission.schedulesProblemReportedAt;
  const wereSchedulesAlreadyValidated = !!mission.schedulesValidatedAt;

  if (wasSchedulesProblemReported || wereSchedulesAlreadyValidated) {
    return false;
  }

  return true;
};

const isCancelledStatus = (status: MissionStatus) =>
  [
    MissionStatus.CANCELLED_BY_CARER_AND_JUSTIFIED,
    MissionStatus.CANCELLED_BY_CARER_AND_NOT_JUSTIFIED,
    MissionStatus.CANCELLED_BY_FACILITY,
    MissionStatus.NOT_CONFIRMED_BY_FACILITY,
  ].includes(status);

export { doesMissionNeedActions, isCancelledStatus };
