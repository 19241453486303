import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { MissionStandard } from '@carers/models/RequestAndMission';
import missionQueries from '@carers/views/Missions/utils/missionQueryFactory';
import { httpService } from '@shared/httpService';

type validateSchedulesDTO = {
  missionId: MissionStandard['id'];
};

const putValidateSchedules = async ({ missionId }: validateSchedulesDTO) => {
  const url = `/missions/${missionId}/validate-schedules`;

  const response = await httpService.put<{ missionId: string }>(url);

  return response.data.missionId;
};

const useValidateSchedules = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: putValidateSchedules,
    onSettled(data, error) {
      queryClient.removeQueries({ queryKey: missionQueries.all() });

      if (error?.code === AxiosError.ERR_NETWORK) {
        return null;
      }

      return Promise.all([
        ...[],
        queryClient.invalidateQueries({ queryKey: missionQueries.lists(), refetchType: 'all' }),
      ]);
    },
  });
};

export { useValidateSchedules, type validateSchedulesDTO };
