import { IonCard, IonCardContent } from '@ionic/react';
import { Trans } from 'react-i18next';

import styles from './UserTasks.module.css';

type UserTasksListProps = {
  label: string;
  value: number;
  icon: string;
  action: () => void;
};

const UserTaskCard = ({ label, value, icon, action }: UserTasksListProps) => {
  return (
    <IonCard className={styles.cardTask} button onClick={action} disabled={value <= 0}>
      <IonCardContent className={styles.cardTaskContent}>
        <img src={icon} alt="" width="40" height="40" />
        <p className={styles.cardLabel}>
          <Trans i18nKey={label} count={value} />
        </p>
      </IonCardContent>
    </IonCard>
  );
};

export default UserTaskCard;
