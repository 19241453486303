import { useIonRouter } from '@ionic/react';
import { useSessionStorage } from 'usehooks-ts';

import { DEFAULT_MAIN_ROUTE } from '@shared/routes/appRoutes';

const STORAGE_KEY = 'redirectPathAfterSignIn';

const useRedirectPathAfterSignIn = () => {
  const [redirectPath, setRedirectPathAfterSignIn] = useSessionStorage<string | null>(
    STORAGE_KEY,
    null,
  );
  const router = useIonRouter();

  const hasRedirectPath = !!redirectPath;

  const clearRedirectAfterLogin = () => setRedirectPathAfterSignIn(null);

  const redirectAfterSignIn = () => {
    if (hasRedirectPath) {
      router.push(redirectPath, 'forward', 'pop');
      clearRedirectAfterLogin();
    } else {
      router.push(DEFAULT_MAIN_ROUTE, 'forward', 'replace');
    }
  };

  return {
    setRedirectPathAfterSignIn,
    redirectAfterSignIn,
  };
};

export default useRedirectPathAfterSignIn;
