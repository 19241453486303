import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

type MinimumVersionContextType = {
  isUpdateRequired: boolean;
  triggerUpdateRequired: () => void;
};

const MinimumVersionContext = createContext<MinimumVersionContextType>({
  isUpdateRequired: false,
  triggerUpdateRequired: () => {},
});

type MinimumVersionProviderProps = { children: ReactNode };

const MinimumVersionProvider = ({ children }: MinimumVersionProviderProps) => {
  const [isUpdateRequired, setIsUpdateRequired] = useState(false);

  const triggerUpdateRequired = () => setIsUpdateRequired(true);

  const contextValue = useMemo(
    () => ({ isUpdateRequired, triggerUpdateRequired }),
    [isUpdateRequired],
  );

  return (
    <MinimumVersionContext.Provider value={contextValue}>{children}</MinimumVersionContext.Provider>
  );
};

const useMinimumVersion = () => useContext(MinimumVersionContext);

export { MinimumVersionProvider, useMinimumVersion };
