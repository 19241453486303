import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericAxiosResponseError } from '@shared/HttpInterceptors/GenericAxiosResponseError';
import {
  GENERIC_STATUS_CODES,
  HTTP_ERROR_CODES_MESSAGES,
  HTTPErrorCode,
  HTTPSErrorStatusCode,
} from '@shared/HttpInterceptors/HTTP_ERROR_CODES';
import { httpService } from '@shared/httpService';

function getErrorMessage(error: GenericAxiosResponseError): string {
  const { code, response, config } = error;

  if (code === AxiosError.ERR_NETWORK) {
    return 'common.messages.errors.offline';
  }

  const errorCode = response?.data.error?.code;

  if (errorCode === HTTPErrorCode.HTTP_INBOUND_DTO_VALIDATION) {
    return config.validationErrorMessage
      ? config.validationErrorMessage
      : 'common.messages.errors.invalidFormat';
  }

  if (errorCode && HTTP_ERROR_CODES_MESSAGES[errorCode]) {
    return HTTP_ERROR_CODES_MESSAGES[errorCode];
  }

  const responseStatus = response?.status as HTTPSErrorStatusCode | undefined;

  if (responseStatus && GENERIC_STATUS_CODES[responseStatus]) {
    return GENERIC_STATUS_CODES[responseStatus];
  }

  return 'common.messages.errors.generic';
}

const useHandleErrorMessageInterceptor = () => {
  const { t } = useTranslation('app', { keyPrefix: 'carers' });

  useEffect(() => {
    const interceptorId = httpService.interceptors.response.use(
      (response) => response,
      (error: GenericAxiosResponseError) => {
        const updatedError = error;
        updatedError.errorMessage = t(getErrorMessage(updatedError));

        return Promise.reject(updatedError);
      },
    );

    return () => httpService.interceptors.response.eject(interceptorId);
  }, [t]);
};

export default useHandleErrorMessageInterceptor;
