import { APP_CONFIG } from '@shared/constants/appConstants';
import { ENV } from '@shared/environments/environment';

import styles from './EnvironmentBanner.module.css';

type EnvironmentBannerProps = {
  position?: 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left';
  color?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'danger'
    | 'success'
    | 'warning'
    | 'medium'
    | 'light';
};

const EnvironmentBanner = ({
  position = 'top-right',
  color = 'danger',
}: EnvironmentBannerProps) => {
  const env = ENV;

  return env === APP_CONFIG.ENV.PROD ? null : (
    <div className={`${styles.banner} ${position} ion-color-${color}`}>{env.toUpperCase()}</div>
  );
};

export default EnvironmentBanner;
