import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18nextHttp from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { APP_CONFIG } from '@shared/constants/appConstants';

i18next
  .use(LanguageDetector)
  .use(i18nextHttp)
  .use(initReactI18next)
  .init({
    supportedLngs: APP_CONFIG.SUPPORTED_LANGUAGES,
    fallbackLng: APP_CONFIG.DEFAULT_LANGUAGE,
    ns: ['app'],
    defaultNS: 'app',
    debug: import.meta.env.DEV,
    backend: {
      loadPath: `/locales/{{lng}}.json`,
    },
    detection: {
      order: ['navigator'],
      caches: [],
    },
  })
  .catch(() => {
    throw Error('[i18n] init error');
  });

i18next.on('languageChanged', (lng: (typeof APP_CONFIG.SUPPORTED_LANGUAGES)[number]) => {
  if ((APP_CONFIG.SUPPORTED_LANGUAGES as unknown as string[]).includes(lng)) {
    document.documentElement.setAttribute('lang', lng);
    document.documentElement.setAttribute('dir', i18next.dir());
  }
});

export default i18next;
