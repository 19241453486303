import { Redirect, Route, RouteProps } from 'react-router-dom';

import useAuthState from '@carers/hooks/useAuthState';
import { DEFAULT_UNAUTHENTICATED_ROUTE } from '@shared/routes/appRoutes';

const AuthProtectedRoute = (props: RouteProps) => {
  const { isAuth } = useAuthState();

  return isAuth ? <Route {...props} /> : <Redirect to={DEFAULT_UNAUTHENTICATED_ROUTE} />;
};

export default AuthProtectedRoute;
