import { Children, cloneElement, ReactElement } from 'react';

import useIsScreenXL from '@shared/hooks/useIsScreenXL';

import styles from './PageContentWithSidePanel.module.css';

type PageWithSideContentProps = { sidePanel: ReactElement | null; children: ReactElement };

const PageContentWithSidePanel = ({ children, sidePanel }: PageWithSideContentProps) => {
  const content = Children.only(children);
  const isScreenXL = useIsScreenXL();

  if (sidePanel) {
    return (
      <>
        {cloneElement(content, {
          className: `${content.props.className ? content.props.className : ''} ${
            isScreenXL ? styles.mainContent : ''
          }`,
        })}
        {isScreenXL && (
          <div className={styles.sidePanel}>
            <div className="ion-page">{sidePanel}</div>
          </div>
        )}
      </>
    );
  }

  return cloneElement(content, {
    className: `${content.props.className ? content.props.className : ''}`,
  });
};

export default PageContentWithSidePanel;
