import { useIonLoading } from '@ionic/react';
import { RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@carers/components/Modal';
import UnsubscribeConfirmModalContent from '@carers/views/Profile/components/UnsubscribeModal/UnsubscribeConfirmModalContent';
import UnsubscribeReasonModalContent from '@carers/views/Profile/components/UnsubscribeModal/UnsubscribeReasonModalContent';
import UnsubscribeSuccessModal from '@carers/views/Profile/components/UnsubscribeModal/UnsubscribeSuccessModalContent';
import {
  UnsubscribeInfos,
  useUnsubscribeUser,
} from '@carers/views/Profile/hooks/useUnsubscribeUser';

type UnsubscribeModalProps = {
  refUnsubscribeModal: RefObject<HTMLIonModalElement>;
  cancel: () => Promise<void>;
  logout: () => Promise<void>;
};

const UnsubscribeModal = ({ refUnsubscribeModal, cancel, logout }: UnsubscribeModalProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.profile' });
  const [showIonLoading, dismissIonLoading] = useIonLoading();

  const [step, setStep] = useState<number>(1);
  const [unsubscribeReason, setUnsubscribeReason] = useState<UnsubscribeInfos>();
  const { mutateAsync: unsubscribeUser, isError, error } = useUnsubscribeUser();

  const handleUnsubscribe = async () => {
    await showIonLoading(t('alerts.processingTitle'));
    unsubscribeUser(unsubscribeReason!)
      .then(() => setStep(3))
      .catch(() => null)
      .finally(() => dismissIonLoading());
  };

  const handleUnSubscribeReasonFormSubmit = (reason: { reason: string; explanation: string }) => {
    setUnsubscribeReason(reason);
    setStep(2);
  };

  const onDismiss = async () => {
    await cancel();
    setStep(1);
    setUnsubscribeReason(undefined);
  };

  const getUnsubscribeComponent = () => {
    switch (step) {
      case 1:
        return <UnsubscribeReasonModalContent setReason={handleUnSubscribeReasonFormSubmit} />;
      case 2:
        return (
          <UnsubscribeConfirmModalContent
            dismiss={onDismiss}
            unsubscribe={handleUnsubscribe}
            isError={isError}
            errorMessage={error?.errorMessage}
          />
        );
      default:
        return <UnsubscribeSuccessModal logout={logout} />;
    }
  };

  return (
    <Modal
      ref={refUnsubscribeModal}
      className="auto-height"
      trigger="open-unsubscribe-modal"
      breakpoints={[0, 1]}
      initialBreakpoint={1}
      aria-labelledby="unsubscribe-modal-title"
      onDidDismiss={onDismiss}
    >
      <div className="ion-padding-horizontal ion-padding-bottom">{getUnsubscribeComponent()}</div>
    </Modal>
  );
};

export default UnsubscribeModal;
