import { IonChip } from '@ionic/react';

import { MissionStatus } from '@carers/views/Missions/models/Missions';

const COLORS: Record<string, [string, string]> = {
  purple: ['#F8F1FF', '#8654C5'],
  orange: ['#FFF1EA', '#CC4E0A'],
  blue: ['#EBF9FF', '#08799C'],
  green: ['#E4FFF0', '#1E824C'],
  red: ['#FFF0F0', '#DC143C'],
};

const STATUS_COLORS: Record<MissionStatus, [string, string]> = {
  AWAITING_VALIDATION: COLORS.purple,
  PENDING_SIGNATURE_REQUEST: COLORS.orange,
  MISSION_SCHEDULES_AWAITING_VALIDATION: COLORS.orange,
  CANCELLED_BY_CARER_AND_NOT_JUSTIFIED: COLORS.orange,
  CONFIRMED: COLORS.blue,
  COMPLETED: COLORS.green,
  NOT_CONFIRMED_BY_FACILITY: COLORS.red,
  CANCELLED_BY_FACILITY: COLORS.red,
  CANCELLED_BY_CARER_AND_JUSTIFIED: COLORS.red,
};

type MissionStatusChipProps = {
  status: MissionStatus;
  label: string;
};

const MissionStatusChip = ({ status, label }: MissionStatusChipProps) => {
  const [background, color] = STATUS_COLORS[status];

  return (
    <IonChip style={{ backgroundColor: background, color }} disabled>
      {label}
    </IonChip>
  );
};

export default MissionStatusChip;
