import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonLabel,
  IonLoading,
  IonPage,
  IonText,
} from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';

import { Modal } from '@carers/components/Modal';
import AuthHeader from '@carers/views/Auth/components/AuthHeader';
import useLoginMode from '@carers/views/Auth/hooks/useLoginMode';
import { useRequestSigninCodeOnOTP } from '@carers/views/Auth/hooks/useRequestSigninCode';
import CodeNotReceived from '@carers/views/Auth/OTPCode/components/CodeNotReceived';
import useGetLoginWith from '@carers/views/Auth/OTPCode/hooks/useGetLoginWith';
import useSignIn from '@carers/views/Auth/OTPCode/hooks/useSignIn';
import { OTPTypeCode } from '@carers/views/Auth/OTPCode/OTPCodeModal';
import { Logo, ShadeEnum } from '@shared/components/Logo/Logo';

import LogoHandWithLock from '../assets/auth-image.svg';
import stylesAuth from '../Auth.module.css';

import styles from './OTPCode.module.css';

const OTP_LENGTH = 6;

const OTPCode = () => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.auth.OTP' });
  const { setLoginMode: goToLoginPage } = useLoginMode();
  const { loginMode, loginValue } = useGetLoginWith();
  const loginModeValues = OTPTypeCode[loginMode];
  const [otp, setOtp] = useState('');
  const codeNotReceivedModal = useRef<HTMLIonModalElement>(null);
  const {
    data: nextRetryAllowedAt,
    isPending: isRequestSigninPending,
    mutate: requestSignin,
    error: errorRequestSigninCode,
    isError: isErrorRequestSigninCode,
  } = useRequestSigninCodeOnOTP();
  const {
    mutate: loginMutation,
    isPending: isSigninPending,
    error: errorRequestSignin,
    isError: isErrorRequestSignin,
  } = useSignIn();
  const isLoading = isSigninPending || isRequestSigninPending;

  const dismissCodeNotReceivedModal = async () => {
    await codeNotReceivedModal.current?.dismiss();
  };

  const switchAuthMethod = async () => {
    await dismissCodeNotReceivedModal();
    goToLoginPage(loginModeValues.otherType, 'root');
  };

  const sendNewRequestCode = () => {
    requestSignin({ mode: loginMode, value: loginValue });
  };

  useEffect(() => {
    if (otp.length === OTP_LENGTH) {
      loginMutation({ mode: loginMode, value: loginValue, code: otp });
      setOtp('');
    }
  }, [otp, loginMode, loginValue, loginMutation]);

  return (
    <IonPage>
      <IonLoading
        isOpen={isLoading}
        message={t(`messages.info.${isSigninPending ? 'signin' : 'sendingCode'}`)}
      />
      <AuthHeader>
        <IonButtons slot="start">
          <IonBackButton mode="md" />
        </IonButtons>
      </AuthHeader>
      <IonContent className="ion-padding">
        <div className={`${styles.pageContent} ${stylesAuth.pageContent} stack stack-large`}>
          <Logo className="ion-hide-xl-down" aria-hidden shade={ShadeEnum.dark} />
          <img src={LogoHandWithLock} alt="" className="ion-hide-xl-down" aria-hidden />
          <IonLabel>{t(loginModeValues.infoCode, { codeLength: OTP_LENGTH })}</IonLabel>
          <form>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={OTP_LENGTH}
              placeholder={''.padEnd(OTP_LENGTH, '0')}
              inputType="number"
              containerStyle={styles.otpInputContainer}
              inputStyle={`${styles.otpInput} ${isErrorRequestSignin && styles.isInvalid}`}
              renderInput={(props) => {
                return <input {...props} autoComplete="one-time-code" />;
              }}
            />
            {isErrorRequestSignin && (
              <IonText className="error-text">{errorRequestSignin.errorMessage}</IonText>
            )}
          </form>
          <IonButton fill="clear" className="button-link" id="open-code-not-received-modal">
            {t(loginModeValues.notReceived)}
          </IonButton>
          <IonButton fill="clear" className="button-link" onClick={switchAuthMethod}>
            {t(loginModeValues.switchText)}
          </IonButton>
        </div>

        <Modal
          ref={codeNotReceivedModal}
          className="auto-height"
          trigger="open-code-not-received-modal"
          breakpoints={[0, 1]}
          initialBreakpoint={1}
          aria-labelledby="code-not-received-modal-title"
        >
          <CodeNotReceived
            loginMode={loginMode}
            loginValue={loginValue}
            switchAuthMethod={switchAuthMethod}
            sendNewRequestCode={sendNewRequestCode}
            nextRetryAllowedAt={nextRetryAllowedAt}
            error={errorRequestSigninCode}
            isError={isErrorRequestSigninCode}
          />
        </Modal>
      </IonContent>
    </IonPage>
  );
};

export default OTPCode;
