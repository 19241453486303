import { IonButton, IonContent, IonFooter, IonLabel, IonToolbar, useIonRouter } from '@ionic/react';
import { AxiosError } from 'axios';
import { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Mission } from '@carers/views/Missions/models/Missions';
import {
  wasSchedulesProblemAlreadyReported,
  wereSchedulesAlreadyValidated,
} from '@carers/views/Missions/utils/missionErrors';
import ErrorImage from '@shared/assets/images/status/generic-error.svg';
import SuccessImage from '@shared/assets/images/status/generic-success.svg';
import NetworkErrorImage from '@shared/assets/images/status/network-error.svg';
import Alert, { AlertProps } from '@shared/components/Alert/Alert';
import BlankSlate from '@shared/components/BlankSlate/BlankSlate';
import useIsScreenXL from '@shared/hooks/useIsScreenXL';
import { GenericAxiosResponseError } from '@shared/HttpInterceptors/GenericAxiosResponseError';

type SchedulesValidationActionResultType = {
  message?: string;
  action?: () => void;
  actionName?: string;
  image?: string;
  alertConfig?: {
    title: string;
    message: string;
    type: AlertProps['type'];
  };
};

type SchedulesValidationActionResultProps = {
  onRetry: (event?: FormEvent<HTMLFormElement>) => Promise<void>;
  dismissModal: () => Promise<unknown>;
  mission?: Mission;
  error?: GenericAxiosResponseError;
  setShowActionComponent: (show: boolean) => void;
  successMessageContent: { title: string; subtitle: string; message: string };
};

const SchedulesValidationActionResult = ({
  onRetry,
  dismissModal,
  mission,
  error,
  setShowActionComponent,
  successMessageContent,
}: SchedulesValidationActionResultProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.missions.detail' });
  const isScreenXL = useIsScreenXL();
  const router = useIonRouter();

  const goToMissionDetail = async () => {
    router.push(`/carers/missions/${mission?.id}`, 'back', 'replace');
    setShowActionComponent(false);

    if (!isScreenXL) {
      await dismissModal();
    }
  };

  const getActionResultConfigOnError = ({
    code,
    response,
    errorMessage,
  }: GenericAxiosResponseError): SchedulesValidationActionResultType => {
    if (response?.data.error?.code) {
      const actionResult: Partial<SchedulesValidationActionResultType> = {
        message: errorMessage,
        image: ErrorImage,
        actionName: t('actions.visualizeMission'),
        action: goToMissionDetail,
      };

      if (wereSchedulesAlreadyValidated(response.data.error.code)) {
        return {
          ...actionResult,
          alertConfig: {
            title: t('alerts.alreadyValidatedSchedules.subtitle'),
            message: t('alerts.alreadyValidatedSchedules.message'),
            type: 'danger',
          },
        };
      }

      if (wasSchedulesProblemAlreadyReported(response.data.error.code)) {
        return {
          ...actionResult,
          alertConfig: {
            title: t('alerts.alreadyReportedSchedulesProblem.subtitle'),
            message: t('alerts.alreadyReportedSchedulesProblem.message'),
            type: 'danger',
          },
        };
      }
    }

    return {
      message: errorMessage,
      image: code === AxiosError.ERR_NETWORK ? NetworkErrorImage : ErrorImage,
      actionName: t('actions.retry'),
      action: onRetry,
    };
  };

  const configActionResult: SchedulesValidationActionResultType = error
    ? getActionResultConfigOnError(error)
    : {
        message: successMessageContent.title,
        image: SuccessImage,
        alertConfig: {
          title: successMessageContent.subtitle,
          message: successMessageContent.message,
          type: 'info',
        },
        actionName: t('actions.visualizeMission'),
        action: goToMissionDetail,
      };

  return (
    <>
      <IonContent>
        <BlankSlate image={configActionResult.image}>
          <h5 color="primary" className="ion-no-margin">
            {configActionResult.message}
          </h5>
          {(configActionResult.alertConfig?.title || configActionResult.alertConfig?.message) && (
            <Alert
              type={configActionResult.alertConfig.type}
              title={configActionResult.alertConfig.title}
            >
              <IonLabel>{configActionResult.alertConfig.message}</IonLabel>
            </Alert>
          )}
        </BlankSlate>
      </IonContent>
      <IonFooter>
        <IonToolbar color="light">
          <div slot="primary" className="stack ion-padding">
            <IonButton fill="outline" expand="block" onClick={configActionResult.action}>
              {configActionResult.actionName}
            </IonButton>
          </div>
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export { SchedulesValidationActionResult };
export type { SchedulesValidationActionResultProps };
