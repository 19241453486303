import { App } from '@capacitor/app';
import { StatusBar, Style } from '@capacitor/status-bar';
import { isPlatform } from '@ionic/react';
import { useEffectOnce } from 'usehooks-ts';

import { delay, setMetaTag } from '@shared/utils/utils-fn';

function getToolbarBackgroundColor(): string {
  const hexaColorLength = 7;

  const color = window
    .getComputedStyle(window.document.documentElement)
    .getPropertyValue('--ion-toolbar-background')
    .trim();

  // Transform #fff into #ffffff
  return color.padEnd(hexaColorLength, color.at(-1));
}

const setStatusBarStyle = () => {
  return Promise.all([
    ...(isPlatform('android')
      ? [StatusBar.setBackgroundColor({ color: getToolbarBackgroundColor() })]
      : []),
    StatusBar.setStyle({ style: Style.Dark }),
  ]);
};

const setUpHybridPlatform = async () => {
  // eslint-disable-next-line no-console
  console.info('App launch', await App.getInfo()); //

  await setStatusBarStyle()
    .then(() => delay(600))
    .then(() => setStatusBarStyle()); // HACK Reset the status bar style, it seems to be a bug on Android 13
};

const setUpWebPlatform = () => {
  setMetaTag('theme-color', getToolbarBackgroundColor());
};

const useSetUpHybridPlatform = () => {
  useEffectOnce(() => {
    if (isPlatform('capacitor')) {
      document.addEventListener('deviceready', setUpHybridPlatform, { once: true });
    } else {
      setUpWebPlatform();
    }
  });
};

export default useSetUpHybridPlatform;
