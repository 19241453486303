import { IonLoading } from '@ionic/react';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useReportSchedulesProblem from '@carers/views/Missions/hooks/useReportSchedulesProblem';
import { SchedulesValidationActionResult } from '@carers/views/Missions/MissionDetail/components/ActionResult/SchedulesValidationActionResult';
import ReportScheduleProblemForm from '@carers/views/Missions/MissionDetail/components/ReportSchedulesProblem/ReportScheduleProblemForm';
import { Mission } from '@carers/views/Missions/models/Missions';

type ReportSchedulesProblemProps = {
  mission: Mission;
  dismissModal: () => Promise<void>;
  setShowReportSchedulesProblem: (show: boolean) => void;
};

type ReportSchedulesProblem = {
  reason: string;
  comment: string;
};

const ReportSchedulesProblem = ({
  mission,
  dismissModal,
  setShowReportSchedulesProblem,
}: ReportSchedulesProblemProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.missions.detail' });

  const {
    mutate: reportScheduleProblem,
    isPending,
    error,
    isError,
    isSuccess,
  } = useReportSchedulesProblem();
  const isSettled = isError || isSuccess;

  const successMessageContent = {
    title: t('alerts.scheduleProblemReportSent.title'),
    subtitle: t('alerts.scheduleProblemReportSent.subtitle'),
    message: t('alerts.scheduleProblemReportSent.message'),
  };

  const [reportScheduleProblemReason, setReportScheduleProblemReason] =
    useState<ReportSchedulesProblem>({
      reason: '',
      comment: '',
    });

  const handleSubmitReport = async (event?: FormEvent<HTMLElement>) => {
    event?.preventDefault();
    reportScheduleProblem({
      missionId: mission.id,
      reason: reportScheduleProblemReason.reason,
      comment: reportScheduleProblemReason.comment,
    });
  };

  return (
    <>
      <IonLoading isOpen={isPending} message={t('actions.sendingReport')} />

      {isSettled ? (
        <SchedulesValidationActionResult
          dismissModal={dismissModal}
          error={error ?? undefined}
          mission={mission}
          onRetry={handleSubmitReport}
          setShowActionComponent={setShowReportSchedulesProblem}
          successMessageContent={successMessageContent}
        />
      ) : (
        <ReportScheduleProblemForm
          mission={mission}
          dismissModal={dismissModal}
          setReportScheduleProblemReason={setReportScheduleProblemReason}
          reportScheduleProblemReason={reportScheduleProblemReason}
          handleSubmitReport={handleSubmitReport}
        />
      )}
    </>
  );
};

export default ReportSchedulesProblem;
