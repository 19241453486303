import { IonButton, IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import { ModalTitle } from '@carers/components/Modal';
import UnsubscribeImage from '@shared/assets/images/status/generic-success.svg';
import Alert from '@shared/components/Alert/Alert';

import styles from '../../Profile.module.css';

type UnsubscribeSuccessProps = {
  logout: () => Promise<void>;
};

const UnsubscribeSuccessModal = ({ logout }: UnsubscribeSuccessProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.profile' });

  return (
    <div className={`stack ${styles.safeArea}`}>
      <ModalTitle id="unsubscribe-modal-title">{t('messages.success.unsubscription')}</ModalTitle>
      <img src={UnsubscribeImage} alt="" aria-hidden />
      <Alert type="info" title={t('alerts.processingTitle')}>
        <IonLabel>{t('alerts.processingTextPart1')}</IonLabel>
        <IonLabel>{t('alerts.processingTextPart2')}</IonLabel>
      </Alert>
      <IonButton fill="outline" expand="block" onClick={logout}>
        {t('actions.logoutMe')}
      </IonButton>
    </div>
  );
};

export default UnsubscribeSuccessModal;
