import { useCallback, useEffect, useReducer } from 'react';

import { GenericFilters } from '@carers/hooks/useSearchParamFilter';
import { isObjEmpty } from '@shared/utils/utils-fn';

enum FilterActionKind {
  RESET = 'RESET',
  SET = 'SET',
  UPDATE = 'UPDATE',
}

type FilterAction = {
  type: FilterActionKind;
  payload?: GenericFilters;
};

function filterReducer(state: GenericFilters, action: FilterAction): GenericFilters {
  const { type, payload } = action;

  switch (type) {
    case FilterActionKind.RESET:
      return {};
    case FilterActionKind.SET:
      return { ...payload };
    case FilterActionKind.UPDATE:
      return { ...state, ...payload };
    default:
      return state;
  }
}

const useGenericFilters = <T extends GenericFilters>(initialData: T) => {
  const [state, dispatch] = useReducer(filterReducer, initialData);

  useEffect(() => {
    if (isObjEmpty(initialData)) {
      dispatch({ type: FilterActionKind.RESET });
    }

    dispatch({ type: FilterActionKind.SET, payload: initialData });
  }, [initialData]);

  return {
    filters: state as T,
    resetFilters: useCallback(() => dispatch({ type: FilterActionKind.RESET }), []),
    updateFilters: useCallback(
      (newFilters: T) => dispatch({ type: FilterActionKind.UPDATE, payload: newFilters }),
      [],
    ),
  };
};

export default useGenericFilters;
