import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

import { FIREBASE_CONFIG } from '../environments/environment.ts';

let isFirebaseInitialized: boolean = false;

type LogEventParams = {
  [key: string]: string;
};

const init = async () => {
  if (isFirebaseInitialized) {
    return;
  }

  isFirebaseInitialized = true;

  await FirebaseAnalytics.initializeFirebase(JSON.parse(FIREBASE_CONFIG));
};

const logEvent = async (name: string, params?: LogEventParams) => {
  await FirebaseAnalytics.logEvent({
    name,
    params: params || {},
  });
};

export { init, logEvent };
