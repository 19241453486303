import React from 'react';
import ReactDOM from 'react-dom/client';

import { MinimumVersionProvider } from '@shared/contexts/MinimumVersionContext.tsx';
import initSentry from '@shared/sentry.ts';

import App from './App.tsx';

initSentry();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <MinimumVersionProvider>
      <App />
    </MinimumVersionProvider>
  </React.StrictMode>,
);
