import { IonCard, IonCardContent, IonCardHeader, IonLabel, IonSkeletonText } from '@ionic/react';

import styles from '../CardDetail.module.css';

const CardDetailSkeleton = () => {
  return (
    <IonCard className={`${styles.ionCardDetail} stack`}>
      <IonCardHeader className={styles.ionCardHeader}>
        <IonLabel className={styles.ionHeaderIcon}>
          <IonSkeletonText style={{ height: '20px', width: '30px' }} animated />
        </IonLabel>
      </IonCardHeader>
      <IonCardContent className={styles.ionCardContentDetail}>
        <span className={styles.titleDetail}>
          <IonSkeletonText style={{ height: '20px', width: '100px' }} animated />
        </span>
      </IonCardContent>
    </IonCard>
  );
};

export default CardDetailSkeleton;
