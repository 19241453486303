import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonItem,
  IonSkeletonText,
} from '@ionic/react';

import profileAvatar from '../../assets/ProfileAvatar.svg';
import styles from '../../Profile.module.css';

const ProfileSkeleton = () => {
  return (
    <>
      <IonCard className={`${styles.profileCard}`}>
        <IonCardHeader>
          <div className={`${styles.profileImage}`}>
            <img src={profileAvatar} alt="" />
          </div>
        </IonCardHeader>
        <IonCardContent className="stack">
          <h5>
            <IonSkeletonText animated />
          </h5>
          <h6>
            <IonSkeletonText animated />
          </h6>
          <p>
            <IonSkeletonText animated />
          </p>
        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardContent>
          <h3>
            <IonSkeletonText style={{ width: '100px' }} animated />
          </h3>
          <IonItem lines="none">
            <IonSkeletonText animated />
            <IonSkeletonText animated />
          </IonItem>
        </IonCardContent>
      </IonCard>
      <IonButton fill="clear" disabled>
        <IonSkeletonText animated />
      </IonButton>
      <IonButton fill="clear" disabled>
        <IonSkeletonText animated />
      </IonButton>
      <IonButton fill="clear" disabled>
        <IonSkeletonText animated />
      </IonButton>
    </>
  );
};

export default ProfileSkeleton;
