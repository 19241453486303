import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useLocalStorage } from 'usehooks-ts';

const STORAGE_KEY = 'userAuth';

type UserAuth = {
  accessToken: string;
  refreshToken: string;
};

const useAuthState = () => {
  const queryClient = useQueryClient();
  const [auth, setAuth] = useLocalStorage<UserAuth | null>(STORAGE_KEY, null);

  const clearAuth = useCallback(() => {
    setAuth(null);
    queryClient.clear();
  }, [queryClient, setAuth]);

  return {
    isAuth: !!auth?.accessToken,
    accessToken: auth?.accessToken,
    refreshToken: auth?.refreshToken,
    setAuth,
    clearAuth,
  };
};

export default useAuthState;
