import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import PageContentWithSidePanel from '@carers/components/PageContentWithSidePanel/PageContentWithSidePanel';
import FeedbackButton from '@facilities/components/FeedbackButton/FeedbackButton';
import Header from '@facilities/components/Header/Header';
import { SidePanel } from '@facilities/components/SidePanel/SidePanel';
import { PREFERENCES_WIDGET_URL } from '@facilities/constants/retoolConstants';
import useGetFacility from '@facilities/hooks/useGetFacility';
import formatRetoolWidgetUrl from '@facilities/utils/utils-fn';
import RetoolWidget from '@facilities/views/RetoolWidget/RetoolWidget';

const Preferences = () => {
  const { t } = useTranslation('app', { keyPrefix: 'facilities' });

  const { facilityId } = useParams<{ facilityId: string }>();
  const { data: facility, isSuccess, isLoading } = useGetFacility(facilityId);

  const [shouldShowSidePanel, setShouldShowSidePanel] = useState<boolean>(false);

  const preferencesUrl = formatRetoolWidgetUrl(PREFERENCES_WIDGET_URL, {
    facilityId,
  });

  const getContent = () => {
    return (
      <IonContent className="ion-padding">
        <RetoolWidget url={preferencesUrl} />
      </IonContent>
    );
  };

  const getSidePanel = () => {
    return <SidePanel facilityId={facilityId} setShouldShowSidePanel={setShouldShowSidePanel} />;
  };

  const getContentWithSidePanel = () => {
    return (
      <PageContentWithSidePanel sidePanel={getSidePanel()}>{getContent()}</PageContentWithSidePanel>
    );
  };

  return (
    <IonPage>
      <Header title={t('common.menu.preferences')} facility={facility} />
      {isLoading && <IonSpinner />}
      {isSuccess && !shouldShowSidePanel && (
        <FeedbackButton setShouldShowSidePanel={setShouldShowSidePanel} />
      )}
      {isSuccess && shouldShowSidePanel ? getContentWithSidePanel() : getContent()}
    </IonPage>
  );
};

export default Preferences;
