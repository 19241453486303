import { IonCard, IonCardContent, IonCardHeader } from '@ionic/react';
import { useCallback } from 'react';

import { List, ListActionKind, ListItem, listReducer } from '@shared/utils/ListReducer.ts';

import CardFilterListItem from './CardFilterListItem.tsx';

type CardFilterListProps = {
  title: string;
  filtersList: Record<ListItem, string>;
  activeKeys?: List;
  handleFilterChange: (activeKeys: List) => void;
};

const CardFilterList = ({
  title,
  filtersList,
  activeKeys = [],
  handleFilterChange,
}: CardFilterListProps) => {
  const listChange = useCallback(
    (item: ListItem, isActive: boolean) => {
      handleFilterChange(
        listReducer(activeKeys, {
          type: isActive ? ListActionKind.REMOVE : ListActionKind.ADD,
          payload: item,
        }),
      );
    },
    [activeKeys, handleFilterChange],
  );

  return (
    <IonCard>
      <IonCardHeader>
        <h5 className="ion-no-margin">{title}</h5>
      </IonCardHeader>
      <IonCardContent>
        {Object.entries(filtersList).map(([key, label]) => {
          const isActive = activeKeys.includes(key);

          return (
            <CardFilterListItem
              label={label}
              isActive={isActive}
              key={key}
              onClick={() => listChange(key, isActive)}
            />
          );
        })}
      </IonCardContent>
    </IonCard>
  );
};

export default CardFilterList;
