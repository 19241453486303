import { Location } from 'history';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { useEffectOnce } from 'usehooks-ts';

/**
 * This hook is a HACK to return only the search params of the initial page when the hook is used
 * With Ionic location change and rerender component with next page search params.
 * This hook prevent this behavior
 * @returns Page search params
 */
const usePageSearchParams = () => {
  const location = useLocation<undefined>();
  const initialLocationRef = useRef<Location<undefined>>();
  const initialLocation = initialLocationRef.current;

  useEffectOnce(() => {
    initialLocationRef.current = location;
  });

  useEffect(() => {
    if (location.pathname === initialLocation?.pathname) {
      initialLocationRef.current = location;
    }
  }, [location, initialLocation?.pathname]);

  return initialLocation && location.pathname !== initialLocation.pathname
    ? new URLSearchParams(initialLocation.search)
    : new URLSearchParams(location.search);
};

export default usePageSearchParams;
