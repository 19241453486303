import { useMutation, useQueryClient } from '@tanstack/react-query';

import userAccountQueries from '@carers/views/Profile/utils/AccountQueryFactory';
import requestQueries from '@carers/views/Requests/utils/requestQueryFactory';
import { httpService } from '@shared/httpService';

type UnsubscribeInfos = { reason: string; explanation: string };

const unsubscribeUser = async (unsubscribeInfos: UnsubscribeInfos) => {
  const url = `/unsubscribe`;

  return httpService.put<void>(url, unsubscribeInfos).then((response) => response.data);
};

const useUnsubscribeUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: unsubscribeUser,
    onSuccess: () => {
      return Promise.all([
        queryClient.setQueryData(userAccountQueries.detail().queryKey, (currentValue) =>
          currentValue ? { ...currentValue, hasPendingUnsubscription: true } : undefined,
        ),
        queryClient.removeQueries({ queryKey: requestQueries.all() }),
      ]);
    },
  });
};

export { useUnsubscribeUser, type UnsubscribeInfos };
