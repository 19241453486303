import { IonCheckbox, IonItem, IonLabel, IonList } from '@ionic/react';
import React, { useCallback } from 'react';

import {
  MissionScheduleBreakDuration,
  MissionScheduleDate,
  MissionScheduleTime,
} from '@carers/components/MissionScheduleDates/MissionScheduleDateDetail';
import { DayScheduleLight, DayScheduleRequest } from '@carers/models/RequestAndMission';
import { List, ListActionKind, ListItem, listReducer } from '@shared/utils/ListReducer';

import styles from './MissionScheduleDates.module.css';

interface MissionScheduleDatesProps {
  daySchedules: DayScheduleLight[] | DayScheduleRequest[];
  isEditable?: boolean;
  isSplittable?: boolean;
  checkedScheduleIds?: List;
  onCheckboxChange?: (checkedIds: List) => void;
}

const MissionScheduleDates = ({
  daySchedules,
  isEditable = false,
  isSplittable = true,
  checkedScheduleIds = [],
  onCheckboxChange,
}: MissionScheduleDatesProps) => {
  const listChange = useCallback(
    (item: ListItem) => {
      onCheckboxChange?.(
        listReducer(checkedScheduleIds, { type: ListActionKind.TOGGLE, payload: item }),
      );
    },
    [checkedScheduleIds, onCheckboxChange],
  );

  return (
    <IonList>
      {daySchedules.map((schedule) => (
        <IonItem key={schedule.id} className={styles.ionItem} lines="none">
          {isEditable ? (
            <IonCheckbox
              alignment="start"
              labelPlacement="end"
              className={styles.ionCheckbox}
              disabled={!isSplittable}
              checked={isSplittable ? checkedScheduleIds.includes(schedule.id) : true}
              onIonChange={() => listChange(schedule.id)}
            >
              <div className={styles.labelFirstLine}>
                <MissionScheduleDate
                  schedule={schedule}
                  shouldDateBeHighlighted={checkedScheduleIds.includes(schedule.id)}
                  shouldConflictBeHighlighted
                />
                <MissionScheduleTime schedule={schedule} />
              </div>
              <MissionScheduleBreakDuration schedule={schedule} />
            </IonCheckbox>
          ) : (
            <IonLabel className="ion-no-margin">
              <div className={styles.labelFirstLine}>
                <MissionScheduleDate schedule={schedule} shouldDateBeHighlighted />
                <MissionScheduleTime schedule={schedule} />
              </div>
              <MissionScheduleBreakDuration schedule={schedule} />
            </IonLabel>
          )}
        </IonItem>
      ))}
    </IonList>
  );
};

export default MissionScheduleDates;
