import { IonChip, IonIcon, IonLabel } from '@ionic/react';

import CheckIcon from '@shared/assets/icons/check.svg';

import styles from './CardFilterListItem.module.css';

type CardChipItemProps = {
  label: string;
  isActive: boolean;
  onClick: () => void;
};

const CardFilterListItem = ({ label, isActive, onClick }: CardChipItemProps) => {
  return (
    <IonChip
      className={`${styles.chipItem} ${isActive ? styles.chipItemActive : ''}`}
      onClick={onClick}
    >
      {isActive && <IonIcon src={CheckIcon} />}
      <IonLabel>{label}</IonLabel>
    </IonChip>
  );
};

export default CardFilterListItem;
