import { IonButton, IonLabel, useIonRouter } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import { DATE_CONFIG, useFormatDate } from '@carers/hooks/useFormatDate';
import MissionPropertyCardScaffold from '@carers/views/Missions/MissionDetail/components/Cards/MissionPropertyCardScaffold';
import { Mission } from '@carers/views/Missions/models/Missions';
import PaymentsIcon from '@shared/assets/icons/payments.svg';

import styles from '../../MissionDetail.module.css';

type MissionPaymentCardProps = {
  mission: Mission;
};

const MissionPaymentCard = ({ mission }: MissionPaymentCardProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.missions.detail' });
  const router = useIonRouter();
  const { formatDate } = useFormatDate();

  let content: JSX.Element | null = null;

  if (mission.isCarerSiretIdEmpty) {
    content = (
      <div className="stack">
        <IonLabel color="primary">{t('noSiret')}</IonLabel>
        <IonButton onClick={() => router.push('/carers/profile', 'root')}>
          {t('actions.goToProfile')}
        </IonButton>
      </div>
    );
  } else if (mission.paymentDueOn !== null && mission.paymentNotificationSentAt === null) {
    content = (
      <>
        <IonLabel color="primary">{t('payment.dueOn')}</IonLabel>
        <IonLabel color="primary" className={styles.typoDate}>
          {formatDate(mission.paymentDueOn, DATE_CONFIG.FULL_DATE)}
        </IonLabel>
      </>
    );
  } else {
    content = (
      <>
        <IonLabel color="primary">{t('payment.sentAt')}</IonLabel>
        <IonLabel color="primary" className={styles.typoDate}>
          {mission.paymentNotificationSentAt
            ? formatDate(mission.paymentNotificationSentAt, DATE_CONFIG.FULL_DATE)
            : null}
        </IonLabel>
      </>
    );
  }

  return (
    <MissionPropertyCardScaffold title={t('payment.title')} icon={PaymentsIcon}>
      {content}
    </MissionPropertyCardScaffold>
  );
};

export default MissionPaymentCard;
