import { IonContent, IonPage, IonSpinner, useIonRouter } from '@ionic/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import PageContentWithSidePanel from '@carers/components/PageContentWithSidePanel/PageContentWithSidePanel';
import AirtableCalendarIframe from '@facilities/components/AirtableCalendarIframe';
import FeedbackButton from '@facilities/components/FeedbackButton/FeedbackButton';
import Header from '@facilities/components/Header/Header';
import { SidePanel } from '@facilities/components/SidePanel/SidePanel';
import AirtableCalendarMode from '@facilities/constants/calendarConstants';
import { HOME_WIDGETS_URL } from '@facilities/constants/retoolConstants';
import useGetFacility from '@facilities/hooks/useGetFacility';
import { ANALYTICS_LOG_EVENTS } from '@facilities/utils/Analytics';
import formatRetoolWidgetUrl from '@facilities/utils/utils-fn';
import containerStyles from '@facilities/views/containers.module.css';
import pageStyles from '@facilities/views/Home/Home.module.css';
import RetoolWidget from '@facilities/views/RetoolWidget/RetoolWidget';
import { logEvent } from '@shared/utils/Analytics';

type HomeWidgetsHandleDataType = {
  shouldRedirectToNewRequest: boolean;
  shouldRedirectToTasks: boolean;
};

const Home = () => {
  const { t } = useTranslation('app', { keyPrefix: 'facilities' });

  const { facilityId } = useParams<{ facilityId: string }>();

  const { data: facility, isSuccess, isLoading } = useGetFacility(facilityId);

  const [shouldShowSidePanel, setShouldShowSidePanel] = useState<boolean>(false);

  const baseCalendarUrl = facility?.calendarUrl;
  const weeklyCalendarUrl = baseCalendarUrl
    ? `${baseCalendarUrl}?mode=${AirtableCalendarMode['week ']}`
    : null;

  const homeWidgetsUrl = formatRetoolWidgetUrl(HOME_WIDGETS_URL, {});

  const planningTitle = t('home.planning.title');

  const router = useIonRouter();

  const handleRedirect = async ({
    shouldRedirectToNewRequest,
    shouldRedirectToTasks,
  }: HomeWidgetsHandleDataType) => {
    if (shouldRedirectToNewRequest) {
      void logEvent(ANALYTICS_LOG_EVENTS.NEW_REQUEST_REDIRECT_WIDGET_CLICK);
      router.push(`/facilities/${facilityId}/new-request`);
    }

    if (shouldRedirectToTasks) {
      void logEvent(ANALYTICS_LOG_EVENTS.TASKS_REDIRECT_WIDGET_CLICK);
      router.push(`/facilities/${facilityId}/tasks`);
    }
  };

  const getContent = () => {
    return (
      <IonContent className="ion-padding">
        <div className={containerStyles.fullWidthContainer}>
          <div className={`${pageStyles.planningContainer} ion-padding`}>
            <h2 className={`ion-padding ${pageStyles.planningTitle}`}>{planningTitle}</h2>
            {isSuccess && !!weeklyCalendarUrl ? (
              <AirtableCalendarIframe calendarUrl={weeklyCalendarUrl} title={planningTitle} />
            ) : (
              <p className="ion-padding">{t('home.planning.errorMessage')}</p>
            )}
          </div>
          <div style={{ flex: '30%' }}>
            <RetoolWidget url={homeWidgetsUrl} handleData={handleRedirect} data={{ facilityId }} />
          </div>
        </div>
      </IonContent>
    );
  };

  const getSidePanel = () => {
    return <SidePanel facilityId={facilityId} setShouldShowSidePanel={setShouldShowSidePanel} />;
  };

  const getContentWithSidePanel = () => {
    return (
      <PageContentWithSidePanel sidePanel={getSidePanel()}>{getContent()}</PageContentWithSidePanel>
    );
  };

  return (
    <IonPage>
      <Header title={t('common.menu.home')} facility={facility} />
      {isLoading && <IonSpinner />}
      {isSuccess && !shouldShowSidePanel && (
        <FeedbackButton setShouldShowSidePanel={setShouldShowSidePanel} />
      )}
      {isSuccess && shouldShowSidePanel ? getContentWithSidePanel() : getContent()}
    </IonPage>
  );
};

export default Home;
