import { IonFab, IonFabButton, IonIcon, IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import FilterIcon from '@shared/assets/icons/filter.svg';

import styles from './ListFilterButton.module.css';

type ListFilterButtonProps = {
  isDisabled: boolean;
  filtersCount: number;
  buttonId: string;
};

const ListFilterButton = ({ buttonId, isDisabled, filtersCount }: ListFilterButtonProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.common' });
  const hasFilter = filtersCount > 0;

  return (
    <IonFab slot="fixed" vertical="bottom" horizontal="center" className={styles.filterFab}>
      <IonFabButton
        className={`${styles.filterButton} ${hasFilter ? styles.hasFilters : ''}`}
        id={buttonId}
        disabled={isDisabled}
      >
        <IonIcon icon={FilterIcon} aria-hidden />
        <IonLabel>
          {hasFilter ? t('actions.filterHasFilter', { filtersCount }) : t('actions.filter')}
        </IonLabel>
      </IonFabButton>
    </IonFab>
  );
};

export default ListFilterButton;
