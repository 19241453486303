/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosError } from 'axios';
import createAuthRefreshInterceptor, {
  AxiosAuthRefreshOptions,
  AxiosAuthRefreshRequestConfig,
} from 'axios-auth-refresh';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

import useAuthState from '@carers/hooks/useAuthState';
import useRedirectPathAfterSignIn from '@carers/hooks/useRedirectPathAfterSignIn';
import { httpService } from '@shared/httpService';

const axiosAuthRefreshOptions: AxiosAuthRefreshOptions = {
  statusCodes: [401],
};

type UserRefreshDTO = {
  accessToken: string;
  refreshToken: string;
};

const sendRefreshToken = async (refreshToken: string) => {
  const url = '/auth/refresh-tokens';
  const config: AxiosAuthRefreshRequestConfig = { skipAuthRefresh: true };

  return httpService
    .post<UserRefreshDTO>(url, { refreshToken }, config)
    .then((response) => response.data);
};

const useAuthTokenInterceptor = () => {
  const { isAuth, accessToken, refreshToken, setAuth, clearAuth } = useAuthState();
  const { setRedirectPathAfterSignIn } = useRedirectPathAfterSignIn();
  const { pathname } = useLocation();

  useEffect(() => {
    let currentAccessToken = accessToken;

    const authorizationInterceptorId = httpService.interceptors.request.use((request) => {
      if (currentAccessToken) {
        request.headers.Authorization = `Bearer ${currentAccessToken}`;
      }

      return request;
    });

    const invalidTokenInterceptorId = createAuthRefreshInterceptor(
      httpService,
      async (failedRequest: AxiosError) => {
        if (isAuth) {
          return sendRefreshToken(refreshToken!)
            .then((response) => {
              setAuth(response);
              currentAccessToken = response.accessToken;

              return Promise.resolve();
            })
            .catch(() => {
              setRedirectPathAfterSignIn(pathname);
              clearAuth();

              return Promise.reject(failedRequest);
            });
        }

        return Promise.reject(failedRequest);
      },
      axiosAuthRefreshOptions,
    );

    return () => {
      httpService.interceptors.response.eject(invalidTokenInterceptorId);
      httpService.interceptors.request.eject(authorizationInterceptorId);
    };
  }, [isAuth, refreshToken, accessToken, setAuth, clearAuth, pathname, setRedirectPathAfterSignIn]);
};

export default useAuthTokenInterceptor;
