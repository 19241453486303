const BREVO_TRACKER_SCRIPT_SOURCE = 'https://cdn.brevo.com/js/sdk-loader.js';
const BREVO_TRACKER_SCRIPT_ID = 'brevoTrackerScript';

declare global {
  interface Window {
    Brevo: Array<[string, { client_key: string }]>;
  }
}

let isBrevoInitialized: boolean = false;

const getBrevoTrackerScript = () => {
  const script = document.createElement('script');
  script.id = BREVO_TRACKER_SCRIPT_ID;
  script.src = BREVO_TRACKER_SCRIPT_SOURCE;
  script.async = true;

  script.onload = () => {
    window.Brevo = window.Brevo || [];
    window.Brevo.push([
      'init',
      {
        client_key: import.meta.env.VITE_BREVO_TRACKER_CLIENT_KEY,
      },
    ]);
  };

  return script;
};

const init = () => {
  if (!isBrevoInitialized) {
    const script = getBrevoTrackerScript();
    document.head.appendChild(script);
    isBrevoInitialized = true;
  }

  const cleanupCallback = () => {
    if (!!isBrevoInitialized) {
      const script = document.getElementById(BREVO_TRACKER_SCRIPT_ID);

      if (!!script) {
        document.head.removeChild(script);
      }

      isBrevoInitialized = false;
    }
  };

  return cleanupCallback;
};

export default init;
