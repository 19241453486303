import { RouteAction, RouterDirection, useIonRouter } from '@ionic/react';

import { AuthMode, DEFAULT_AUTH_MODE, isAuthModeValid } from '@carers/views/Auth/AuthMode';
import usePageSearchParams from '@carers/views/Auth/hooks/usePageSearchParams';
import { DEFAULT_UNAUTHENTICATED_ROUTE } from '@shared/routes/appRoutes';

const SEARCH_PARAM_MODE = 'mode';

const useLoginMode = () => {
  const searchParams = usePageSearchParams();
  const router = useIonRouter();

  const loginModeSearchParams = searchParams.get(SEARCH_PARAM_MODE) as AuthMode;
  const loginMode = isAuthModeValid(loginModeSearchParams)
    ? loginModeSearchParams
    : DEFAULT_AUTH_MODE;

  const setLoginMode = (mode: AuthMode, direction?: RouterDirection, action?: RouteAction) => {
    const queryParams = new URLSearchParams({ [SEARCH_PARAM_MODE]: mode });

    router.push(`${DEFAULT_UNAUTHENTICATED_ROUTE}?${queryParams.toString()}`, direction, action);
  };

  return { loginMode, setLoginMode };
};

export default useLoginMode;
