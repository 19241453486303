import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router';

import { APP_ROUTES } from '@shared/routes/appRoutes';

const findRouteTitle = (pathname: string) =>
  APP_ROUTES.find((route) => matchPath(pathname, { path: route.path, exact: true }))?.label;

const setDocumentTitle = (appName: string, title?: string) => {
  window.document.title = title ? `${appName} | ${title}` : appName;
};

const DocumentTitle = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const setTitle = useCallback(
    (pathname: string) => {
      const routeTitle = findRouteTitle(pathname);
      setDocumentTitle(t('shared.appName'), routeTitle ? t(routeTitle) : undefined);
    },
    [t],
  );

  useEffect(() => {
    setTitle(location.pathname);
  }, [location.pathname, setTitle]);

  return false;
};

export default DocumentTitle;
