import { useTranslation } from 'react-i18next';

import brightnessIcon from '@shared/assets/icons/brightness.svg';
import dayIcon from '@shared/assets/icons/day.svg';
import nightIcon from '@shared/assets/icons/night.svg';
import { RYTHMS } from '@shared/constants/missionsConstants';
import { LabelValue } from '@shared/models/LabelValue';

const RythmsIcons = {
  day: dayIcon,
  brightness: brightnessIcon,
  night: nightIcon,
};

const useFormatRythm = (
  rythms: LabelValue<RYTHMS>[],
): { iconRythm: string; wordingRythm: string } => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.common.format' });

  let iconRythm = `${RythmsIcons[rythms[0].value.toLocaleLowerCase() as keyof typeof RythmsIcons]}`;
  let wordingRythm = rythms[0].label;

  if (rythms.length > 1) {
    iconRythm = RythmsIcons.brightness;
    wordingRythm = t('and', { before: rythms[0].label, after: rythms[1].label });
  }

  return { iconRythm, wordingRythm };
};

export default useFormatRythm;
