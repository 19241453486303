import { AuthMode, isAuthModeValid } from '@carers/views/Auth/AuthMode';
import usePageSearchParams from '@carers/views/Auth/hooks/usePageSearchParams';

const useGetLoginWith = () => {
  const searchParams = usePageSearchParams();

  const loginMode: AuthMode = Array.from(searchParams.keys()).at(0) as AuthMode;

  if (!isAuthModeValid(loginMode)) {
    throw new Error('Invalid auth method');
  }

  const loginValue = new URLSearchParams(searchParams).get(loginMode)!;

  return { loginMode, loginValue };
};

export default useGetLoginWith;
