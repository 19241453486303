import { Capacitor } from '@capacitor/core';
import * as Sentry from '@sentry/capacitor';
import * as SentryReact from '@sentry/react';

import { APP_CONFIG } from '@shared/constants/appConstants';

const {
  VITE_API_BASE_URL,
  VITE_ENV,
  VITE_LAST_COMMIT_HASH,
  VITE_SENTRY_DSN,
  VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
  VITE_SENTRY_TRACES_SAMPLE_RATE,
} = import.meta.env;

const initSentry = (): void => {
  if (VITE_ENV === APP_CONFIG.ENV.LOCAL) {
    return;
  }

  Sentry.init(
    {
      dsn: VITE_SENTRY_DSN,
      environment: VITE_ENV,

      integrations: [
        new SentryReact.Replay({ blockAllMedia: false, maskAllInputs: false, maskAllText: false }),
      ],

      release: VITE_LAST_COMMIT_HASH,

      replaysSessionSampleRate: VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
      replaysOnErrorSampleRate: 1.0,

      tracePropagationTargets: ['localhost', VITE_API_BASE_URL],
      tracesSampleRate: VITE_SENTRY_TRACES_SAMPLE_RATE,
    },
    SentryReact.init,
  );

  const platform = Capacitor.getPlatform();

  Sentry.setTag('platform', platform);
};

export default initSentry;
