import { useMediaQuery } from 'usehooks-ts';

import { APP_CONFIG } from '@shared/constants/appConstants';

const useIsScreenXL = () => {
  const isScreenXL = useMediaQuery(APP_CONFIG.BREAKPOINTS.XL);

  return isScreenXL;
};

export default useIsScreenXL;
