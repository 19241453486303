import { PropsWithChildren } from 'react';

import styles from './ModalTitle.module.css';

type ModalTitleProps = {
  id: string;
};

const ModalTitle = ({ children, id }: PropsWithChildren<ModalTitleProps>) => {
  return (
    <h2 className={styles.modalTitle} id={id}>
      {children}
    </h2>
  );
};

export default ModalTitle;
