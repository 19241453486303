import { IonButton, IonSelect, IonSelectOption, IonTextarea } from '@ionic/react';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalTitle } from '@carers/components/Modal';
import useGetAppConstants from '@carers/hooks/useGetAppConstants';
import { UnsubscribeInfos } from '@carers/views/Profile/hooks/useUnsubscribeUser';

import styles from '../../Profile.module.css';

type UnsubscribeReasonProps = {
  setReason: (reason: UnsubscribeInfos) => void;
};

const UnsubscribeReason = ({ setReason }: UnsubscribeReasonProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers' });
  const [unsubscribeReason, setUnsubscribeReason] = useState<UnsubscribeInfos>({
    reason: '',
    explanation: '',
  });
  const { data: appConstants } = useGetAppConstants();

  const canSubmitForm = !!unsubscribeReason.reason && !!unsubscribeReason.explanation;

  const onSubmit = (event: FormEvent<HTMLElement>) => {
    event.preventDefault();
    setReason(unsubscribeReason);
  };

  return (
    <div className={`stack ${styles.safeArea}`}>
      <ModalTitle id="unsubscribe-modal-title">
        {t('profile.messages.unsubscribe.unsubscribe')}
      </ModalTitle>
      <form onSubmit={onSubmit} className="stack">
        <IonSelect
          interface="action-sheet"
          cancelText={t('common.actions.cancel')}
          fill="outline"
          label={t('profile.messages.unsubscribe.unsubscribeReason')}
          labelPlacement="floating"
          onIonChange={(e) =>
            setUnsubscribeReason({ ...unsubscribeReason, reason: e.detail.value })
          }
        >
          {Object.entries(appConstants?.unsubscriptionReasons ?? {}).map(([key, value]) => (
            <IonSelectOption key={key} value={key}>
              {value}
            </IonSelectOption>
          ))}
        </IonSelect>

        <IonTextarea
          label={t('profile.messages.unsubscribe.explanationLabel')}
          labelPlacement="floating"
          placeholder={t('profile.messages.unsubscribe.unsubscribeExplanationPlaceholder')}
          fill="outline"
          rows={6}
          required
          onIonInput={(e) =>
            setUnsubscribeReason({
              ...unsubscribeReason,
              explanation: e.target.value as string,
            })
          }
        />

        <IonButton type="submit" expand="block" disabled={!canSubmitForm}>
          {t('profile.actions.unsubscribe')}
        </IonButton>
      </form>
    </div>
  );
};

export default UnsubscribeReason;
