import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import PageContentWithSidePanel from '@carers/components/PageContentWithSidePanel/PageContentWithSidePanel';
import FeedbackButton from '@facilities/components/FeedbackButton/FeedbackButton';
import Header from '@facilities/components/Header/Header';
import { SidePanel } from '@facilities/components/SidePanel/SidePanel';
import { CARERS_WIDGET_URL } from '@facilities/constants/retoolConstants';
import useGetFacility from '@facilities/hooks/useGetFacility';
import { ANALYTICS_LOG_EVENTS } from '@facilities/utils/Analytics';
import formatRetoolWidgetUrl from '@facilities/utils/utils-fn';
import RetoolWidget from '@facilities/views/RetoolWidget/RetoolWidget';
import { logEvent } from '@shared/utils/Analytics';

type CarersWidgetHandleDataType = {
  shouldRedirectToNewRequest: boolean;
};

const Carers = () => {
  const { t } = useTranslation('app', { keyPrefix: 'facilities' });

  const { facilityId } = useParams<{ facilityId: string }>();
  const { data: facility, isSuccess, isLoading } = useGetFacility(facilityId);

  const [shouldShowSidePanel, setShouldShowSidePanel] = useState<boolean>(false);

  const carersUrl = formatRetoolWidgetUrl(CARERS_WIDGET_URL, {
    facilityId,
  });

  const history = useHistory();

  const handleRedirect = async ({ shouldRedirectToNewRequest }: CarersWidgetHandleDataType) => {
    if (shouldRedirectToNewRequest) {
      void logEvent(ANALYTICS_LOG_EVENTS.REDIRECT_TO_NEW_REQUEST_FROM_CARERS_PAGE_CLICK);
      history.push(`/facilities/${facilityId}/new-request`, { wasRedirectedFromCarersPage: true });
    }
  };

  const getContent = () => {
    return (
      <IonContent className="ion-padding">
        <RetoolWidget url={carersUrl} handleData={handleRedirect} />
      </IonContent>
    );
  };

  const getSidePanel = () => {
    return <SidePanel facilityId={facilityId} setShouldShowSidePanel={setShouldShowSidePanel} />;
  };

  const getContentWithSidePanel = () => {
    return (
      <PageContentWithSidePanel sidePanel={getSidePanel()}>{getContent()}</PageContentWithSidePanel>
    );
  };

  return (
    <IonPage>
      <Header title={t('common.menu.carers')} facility={facility} />
      {isLoading && <IonSpinner />}
      {isSuccess && !shouldShowSidePanel && (
        <FeedbackButton setShouldShowSidePanel={setShouldShowSidePanel} />
      )}
      {isSuccess && shouldShowSidePanel ? getContentWithSidePanel() : getContent()}
    </IonPage>
  );
};

export default Carers;
