import { IonCard, IonCol, IonGrid, IonRow } from '@ionic/react';
import dayjs, { type Dayjs } from 'dayjs';
import { forwardRef, lazy, Suspense, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CardMission from '@carers/components/CardMission/CardMission';
import GridCards from '@carers/components/GridCards/GridCards';
import { ANALYTICS_LOG_EVENTS } from '@carers/utils/Analytics.ts';
import useGetMissionsByMonth from '@carers/views/Activity/hooks/useGetMissionsByMonth';
import { MissionsByMonth } from '@carers/views/Activity/services/getMissionsByMonth';
import { MissionLight } from '@carers/views/Missions/models/Missions';
import { isCancelledStatus } from '@carers/views/Missions/utils/missionHelpers';
import BlankSlate from '@shared/components/BlankSlate/BlankSlate';
import { logEvent } from '@shared/utils/Analytics';

const Calendar = lazy(() => import('@carers/components/Calendar/Calendar'));

const findMissionsOnDay = (
  selectedDay: Dayjs,
  misionsByMonth?: MissionsByMonth,
): MissionLight[] => {
  const missionsIds = misionsByMonth?.daySchedules[selectedDay.format('YYYY-MM-DD')] ?? [];

  return misionsByMonth?.missions ? missionsIds.map((id) => misionsByMonth.missions[id]) : [];
};

const CalendarMissionsByMonth = forwardRef(function CalendarMissionsByMonth(_, ref) {
  const { t } = useTranslation();
  const [displayMonthYear, setDisplayMonth] = useState(dayjs().format('YYYY-MM'));
  const [selectedDay, setSelectedDay] = useState(dayjs());
  const { isLoading, data, isSuccess, refetch } = useGetMissionsByMonth(displayMonthYear);
  const [missionsOfSelectedDay, setMissionsOfSelectedDay] = useState<MissionLight[]>([]);

  useImperativeHandle(ref, () => ({
    refetch,
  }));

  useEffect(() => {
    if (isSuccess && displayMonthYear === selectedDay.format('YYYY-MM')) {
      const missionsOnDay = findMissionsOnDay(selectedDay, data);
      const displayableMissions = missionsOnDay.filter(
        (mission) => !isCancelledStatus(mission.status.value),
      );
      setMissionsOfSelectedDay(displayableMissions);
    }
  }, [selectedDay, displayMonthYear, isSuccess, data]);

  const changeMonthDisplay = async (date: Dayjs) => {
    void logEvent(ANALYTICS_LOG_EVENTS.CALENDAR_CLICK);

    setDisplayMonth(date.format('YYYY-MM'));
  };

  const changeSelectedDay = async (date: Dayjs) => {
    void logEvent(ANALYTICS_LOG_EVENTS.CALENDAR_CLICK);

    setSelectedDay(date);
  };

  const getDaysWithMissions = () => {
    if (!data) {
      return [];
    }

    const displayableMissionIds = Object.values(data.missions)
      .filter((mission) => !isCancelledStatus(mission.status.value))
      .map((mission) => mission.id);

    const displayableDates = Object.keys(data.daySchedules).filter((date) =>
      data.daySchedules[date].some((missionId) => displayableMissionIds.includes(missionId)),
    );

    return displayableDates;
  };

  return (
    <IonGrid className="ion-no-padding">
      <h5>{t('carers.activity.myCalendar')}</h5>
      <IonRow style={{ gap: '16px' }}>
        <IonCol size="12" sizeMd="auto">
          <IonCard className="ion-no-margin">
            <Suspense
              fallback={
                <BlankSlate
                  message={t('shared.messages.info.loading')}
                  style={{ width: '320px', height: '334px' }}
                />
              }
            >
              <Calendar
                daysWithMissions={getDaysWithMissions()}
                isLoading={isLoading}
                selectedDay={selectedDay}
                onSelectedDayChange={changeSelectedDay}
                onMonthChange={changeMonthDisplay}
              />
            </Suspense>
          </IonCard>
        </IonCol>
        {missionsOfSelectedDay.length > 0 && (
          <IonCol>
            <GridCards>
              {missionsOfSelectedDay.map((mission) => (
                <CardMission key={mission.id} mission={mission} />
              ))}
            </GridCards>
          </IonCol>
        )}
      </IonRow>
    </IonGrid>
  );
});

export default CalendarMissionsByMonth;
