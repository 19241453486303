import { IonCard, IonCardContent, IonCardHeader, IonIcon } from '@ionic/react';
import { ReactElement } from 'react';

import styles from './CardDetail.module.css';

interface CardDetailProps {
  icon?: string;
  title?: string;
  titleLight?: string | ReactElement;
  text?: string;
}

const CardDetail = ({ icon, title, titleLight, text }: CardDetailProps) => {
  return (
    <IonCard className={`${styles.ionCardDetail} stack ion-no-margin`}>
      {icon && (
        <IonCardHeader className={styles.ionCardHeader}>
          <IonIcon icon={icon} className={styles.ionHeaderIcon} />
        </IonCardHeader>
      )}
      <IonCardContent className={styles.ionCardContentDetail}>
        <span className={`${styles.titleDetail} stack stack-no-space`}>
          {title}
          <span className={styles.titleLightDetail}>{titleLight}</span>
        </span>
        {text && <div className={styles.textDetail}>{text}</div>}
      </IonCardContent>
    </IonCard>
  );
};

export default CardDetail;
