const USETIFUL_SCRIPT_SOURCE = 'https://www.usetiful.com/dist/usetiful.js';
const USETIFUL_SCRIPT_ID = 'usetifulScript';

let isUsetifulInitialized: boolean = false;

const getUsetifulScript = () => {
  const script = document.createElement('script');
  script.id = USETIFUL_SCRIPT_ID;
  script.src = USETIFUL_SCRIPT_SOURCE;
  script.async = true;
  script.dataset.token = import.meta.env.VITE_USETIFUL_DATASET_TOKEN;

  return script;
};

const init = () => {
  if (!isUsetifulInitialized) {
    const script = getUsetifulScript();
    document.head.appendChild(script);
    isUsetifulInitialized = true;
  }

  const cleanupCallback = () => {
    if (!!isUsetifulInitialized) {
      const script = document.getElementById(USETIFUL_SCRIPT_ID);

      if (!!script) {
        document.head.removeChild(script);
      }

      isUsetifulInitialized = false;
    }
  };

  return cleanupCallback;
};

export default init;
