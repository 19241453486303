import { IonCard, IonCardContent, IonCardHeader, IonIcon } from '@ionic/react';
import { PropsWithChildren } from 'react';

import styles from '../../MissionDetail.module.css';

type MissionPropertyCardScaffoldProps = {
  title: string;
  icon: string;
};

const MissionPropertyCardScaffold = ({
  title,
  icon,
  children,
}: PropsWithChildren<MissionPropertyCardScaffoldProps>) => {
  return (
    <IonCard>
      <IonCardHeader className="stack stack-horizontal">
        <IonIcon aria-hidden="true" icon={icon} className={`${styles.ionIcon}`} />
        <h5>{title}</h5>
      </IonCardHeader>
      <IonCardContent className="stack stack-no-space">{children}</IonCardContent>
    </IonCard>
  );
};

export default MissionPropertyCardScaffold;
