import { IonButton, IonButtons, IonHeader, IonIcon, IonModal, IonToolbar } from '@ionic/react';
import React, {
  ComponentProps,
  forwardRef,
  PropsWithChildren,
  useImperativeHandle,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import closeIcon from '@shared/assets/icons/close.svg';
import useIsScreenXL from '@shared/hooks/useIsScreenXL';

import styles from './Modal.module.css';

type ModalProps = ComponentProps<typeof IonModal>;

const Modal = forwardRef<HTMLIonModalElement, PropsWithChildren<ModalProps>>(function Modal(
  { children, ...props },
  forwardedRef,
) {
  const isScreenXL = useIsScreenXL();
  const { t } = useTranslation('app', { keyPrefix: 'carers' });
  const ref = useRef<HTMLIonModalElement>(null);
  useImperativeHandle(forwardedRef, () => ref.current as HTMLIonModalElement);

  const closeModal = async () => {
    await ref.current?.dismiss(undefined, 'closeButton');
  };

  return (
    <IonModal
      ref={ref}
      {...props}
      breakpoints={isScreenXL ? undefined : props.breakpoints}
      initialBreakpoint={isScreenXL ? undefined : props.initialBreakpoint}
    >
      <IonHeader>
        <IonToolbar className={styles.modalHeader}>
          <IonButtons slot="end">
            <IonButton color="primary" onClick={closeModal} aria-label={t('common.actions.cancel')}>
              <IonIcon slot="icon-only" aria-hidden icon={closeIcon} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      {children}
    </IonModal>
  );
});

export default Modal;
