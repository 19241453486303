import { IonLoading } from '@ionic/react';
import { FormEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DATE_CONFIG, useFormatDate } from '@carers/hooks/useFormatDate';
import { useValidateSchedules } from '@carers/views/Missions/hooks/useValidateSchedules';
import { SchedulesValidationActionResult } from '@carers/views/Missions/MissionDetail/components/ActionResult/SchedulesValidationActionResult';
import { Mission } from '@carers/views/Missions/models/Missions';

type ValidateSchedulesProps = {
  mission: Mission;
  dismissModal: () => Promise<void>;
  setShowValidateSchedules: (show: boolean) => void;
};

const ValidateSchedules = ({
  mission,
  dismissModal,
  setShowValidateSchedules,
}: ValidateSchedulesProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.missions.detail' });
  const { formatDate } = useFormatDate();

  const {
    mutate: validateSchedules,
    isPending,
    error,
    isError,
    isSuccess,
  } = useValidateSchedules();
  const isSettled = isError || isSuccess;

  const paymentDueOn = !!mission.paymentDueOn
    ? formatDate(mission.paymentDueOn, DATE_CONFIG.FULL_DATE).toLowerCase()
    : null;

  const paymentDueOnText = !!paymentDueOn
    ? `${t('alerts.schedulesValidated.paymentDueOnPrefix')} ${paymentDueOn}`
    : null;

  const successMessageContents = mission.isCarerSiretIdEmpty
    ? [t('alerts.schedulesValidated.messageWithoutSiretId'), paymentDueOnText]
    : [t('alerts.schedulesValidated.messageWithSiretId'), paymentDueOnText];

  const successMessageContent = {
    title: t('alerts.schedulesValidated.title'),
    subtitle: t('alerts.schedulesValidated.subtitle'),
    message: `${successMessageContents.filter(Boolean).join(' ')}.`,
  };

  const handleValidateSubmit = async (event?: FormEvent<HTMLElement>) => {
    event?.preventDefault();
    validateSchedules({
      missionId: mission.id,
    });
  };

  useEffect(() => {
    validateSchedules({
      missionId: mission.id,
    });
  }, [mission.id, validateSchedules]);

  return (
    <>
      <IonLoading isOpen={isPending} message={t('actions.validatingSchedules')} />

      {isSettled && (
        <SchedulesValidationActionResult
          dismissModal={dismissModal}
          error={error ?? undefined}
          mission={mission}
          onRetry={handleValidateSubmit}
          setShowActionComponent={setShowValidateSchedules}
          successMessageContent={successMessageContent}
        />
      )}
    </>
  );
};

export default ValidateSchedules;
