import { GenericAbortSignal } from 'axios';

import { MissionLight } from '@carers/views/Missions/models/Missions';
import { MissionLightDTO, MissionsDTO } from '@carers/views/Missions/models/MissionsDTO';
import { MissionsFilters } from '@carers/views/Missions/models/MissionsFilters';
import { httpService } from '@shared/httpService';
import { InfiniteData } from '@shared/models/InfiniteData';
import convertGenericFilterObjectToApiUrlSearchParams from '@shared/utils/APISearchParamsFilter';
import { mapFacilityLightDTOToFacilityLight } from '@shared/utils/RequestAndMissionMapper';

const ITEMS_PER_PAGE = 12;

const getSearchParams = (nextItemId: string, filters: MissionsFilters) => {
  const urlSearchParams = convertGenericFilterObjectToApiUrlSearchParams(filters);

  urlSearchParams.set('size', ITEMS_PER_PAGE.toString());

  if (nextItemId) {
    urlSearchParams.set('nextId', nextItemId);
  }

  return urlSearchParams;
};

const mapMissionLightDTOToMissionLight = (dto: MissionLightDTO): MissionLight => {
  return {
    id: dto.id,
    facility: mapFacilityLightDTOToFacilityLight(dto.facility),
    contract: dto.contract,
    job: dto.job,
    rythms: dto.rythms,
    service: dto.service,
    netRemuneration: dto.netRemuneration,
    grossRemuneration: dto.grossRemuneration,
    start: dto.start,
    end: dto.end,
    status: dto.status,
    paymentNotificationSentAt: dto.paymentNotificationSentAt,
    tasksToCompleteCount: dto.tasksToCompleteCount,
  };
};

const mapMissionsDTOToInfiniteData = (dto: MissionsDTO): InfiniteData<MissionLight> => {
  return {
    data: dto.data.missions.map((missionDTO) => mapMissionLightDTOToMissionLight(missionDTO)),
    nextId: dto.metadata.missions.nextId,
  };
};

const getMissions = async (
  nextItemId: string,
  filters: MissionsFilters,
  signal: GenericAbortSignal,
) => {
  const url = `/missions?${getSearchParams(nextItemId, filters)}`;

  return httpService
    .get<MissionsDTO>(url, { signal })
    .then((response) => mapMissionsDTOToInfiniteData(response.data));
};

export default getMissions;
