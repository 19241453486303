import { IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import Alert from '@shared/components/Alert/Alert';

type AlertProps = {
  shouldShowNotificationsMessage: boolean;
};

const MobileAppDownloadAlert = ({ shouldShowNotificationsMessage }: AlertProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.common.messages.mobileAppDownload' });

  const title = t('title');
  const notificationsMessage = t('notificationsMessage');
  const message = t('message');

  return (
    <Alert type="info" title={title}>
      <IonLabel>{message}</IonLabel>
      {!!shouldShowNotificationsMessage && <IonLabel>{notificationsMessage}</IonLabel>}
    </Alert>
  );
};

export default MobileAppDownloadAlert;
