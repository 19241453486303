import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonToolbar,
} from '@ionic/react';
import { FormEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ScrollableContentButton from '@carers/components/ScrollableContentButton/ScrollableContentButton';
import useGetAppConstants from '@carers/hooks/useGetAppConstants';
import MissionScheduleCard from '@carers/views/Missions/MissionDetail/components/Cards/MissionScheduleCard';
import { Mission } from '@carers/views/Missions/models/Missions';
import useIsIonContentOverflow from '@carers/views/Requests/hooks/useIsIonContentOverflow';
import Alert from '@shared/components/Alert/Alert';

import './ReportScheduleProblemForm.css';

type ScheduleProblemReporting = {
  reason: string;
  comment: string;
};

type ReportScheduleProblemFormProps = {
  mission: Mission;
  dismissModal: () => Promise<void>;
  setReportScheduleProblemReason: (value: ScheduleProblemReporting) => void;
  reportScheduleProblemReason: {
    reason: string;
    comment: string;
  };
  handleSubmitReport: (event?: FormEvent<HTMLElement>) => void;
};

const ReportScheduleProblemForm = ({
  mission,
  dismissModal,
  setReportScheduleProblemReason,
  reportScheduleProblemReason,
  handleSubmitReport,
}: ReportScheduleProblemFormProps) => {
  const { t } = useTranslation('app', {
    keyPrefix: 'carers.missions.detail.reportScheduleProblem',
  });
  const { data: appConstants } = useGetAppConstants();
  const ionContentRef = useRef<HTMLIonContentElement>(null);
  const isIonContentOverflow = useIsIonContentOverflow(ionContentRef);

  const [isAcceptConditionsChecked, setIsAcceptConditionsChecked] = useState(false);

  const onReasonChange = (e: CustomEvent) => {
    setReportScheduleProblemReason({
      ...reportScheduleProblemReason,
      reason: e.detail.value,
    });
  };

  const onCommentChange = (e: CustomEvent) => {
    setReportScheduleProblemReason({
      ...reportScheduleProblemReason,
      comment: e.detail.value,
    });
  };

  const canReportProblem =
    !!reportScheduleProblemReason.reason &&
    !!reportScheduleProblemReason.comment &&
    isAcceptConditionsChecked;

  const getActionButtons = () => {
    return (
      <div className="stack">
        <IonButton expand="block" fill="outline" onClick={dismissModal}>
          {t('actions.cancel')}
        </IonButton>
        <IonButton
          type="submit"
          form="reportScheduleProblemForm"
          expand="block"
          disabled={!canReportProblem}
        >
          {t('actions.report')}
        </IonButton>
      </div>
    );
  };

  return (
    <>
      <IonContent ref={ionContentRef} className="ion-padding">
        <div className="stack stack-large">
          <h5>{t('title')}</h5>
          <IonLabel className="ion-no-margin bold-text">{mission.facility.name}</IonLabel>

          <Alert type="warning" title={t('alerts.cancelMission.title')}>
            <IonLabel>{t('alerts.cancelMission.message')}</IonLabel>
          </Alert>

          <MissionScheduleCard mission={mission} />

          <form onSubmit={handleSubmitReport} id="reportScheduleProblemForm" className="stack">
            <IonSelect
              interface="action-sheet"
              cancelText={t('actions.cancel')}
              fill="outline"
              label={t('reason')}
              labelPlacement="floating"
              onIonChange={onReasonChange}
            >
              {Object.entries(appConstants?.scheduleProblemReportReasons ?? {}).map(
                ([key, value]) => (
                  <IonSelectOption key={key} value={value}>
                    {value}
                  </IonSelectOption>
                ),
              )}
            </IonSelect>

            <IonTextarea
              label={t('explanation')}
              labelPlacement="floating"
              placeholder={t('reasonPlaceholder')}
              fill="outline"
              rows={6}
              required
              onIonInput={onCommentChange}
            />
          </form>

          <IonCheckbox
            labelPlacement="end"
            alignment="center"
            class="ion-text-wrap"
            onIonChange={(e) => setIsAcceptConditionsChecked(e.detail.checked)}
          >
            <span className="ion-text-wrap bold-text">{t('conditions')}</span>
          </IonCheckbox>
        </div>
        {isIonContentOverflow && <ScrollableContentButton ionContentRef={ionContentRef.current} />}
      </IonContent>
      <IonFooter className="stack ion-padding">
        <IonToolbar color="light">
          <div slot="primary" className="stack">
            {getActionButtons()}
          </div>
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export default ReportScheduleProblemForm;
