import { GenericFilters } from '@carers/hooks/useSearchParamFilter';

function convertGenericFilterObjectToApiUrlSearchParams(filters: GenericFilters) {
  const keyValues = [];

  for (const [key, value] of Object.entries(filters)) {
    if (typeof value === 'string') {
      keyValues.push([key, value]);
    } else if (Array.isArray(value) && value.length > 0) {
      value.forEach((v) => {
        keyValues.push([key, v]);
      });
    }
  }

  return new URLSearchParams(keyValues);
}

export default convertGenericFilterObjectToApiUrlSearchParams;
