import { useTranslation } from 'react-i18next';

type TimeFormatProps = {
  start: string;
  end: string;
  formatOptions?: Intl.DateTimeFormatOptions;
};

const DEFAULT_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  timeStyle: 'short',
  timeZone: 'Europe/Paris',
};

const useFormatTime = (locale?: string) => {
  const { i18n } = useTranslation('app', { keyPrefix: 'carers' });
  const language = locale ?? i18n.language;

  const formatTimeRange = ({
    start,
    end,
    formatOptions = DEFAULT_FORMAT_OPTIONS,
  }: TimeFormatProps): string => {
    const startDateTime = new Date(start);
    const endDateTime = new Date(end);

    const formatter = Intl.DateTimeFormat(language, formatOptions);

    return `${formatter.format(startDateTime)} - ${formatter.format(endDateTime)}`;
  };

  return { formatTimeRange };
};

export default useFormatTime;
