import { GenericAbortSignal } from 'axios';

import { Request } from '@carers/views/Requests/models/Requests';
import { RequestDTO } from '@carers/views/Requests/models/RequestsDTO';
import { httpService } from '@shared/httpService';
import {
  mapDayScheduleRequestDTOToDayScheduleRequest,
  mapFacilityDTOToFacility,
  mapLeadDTOToLead,
} from '@shared/utils/RequestAndMissionMapper';

const mapRequestDTOToRequest = (dto: RequestDTO): Request => {
  return {
    id: dto.id,
    contract: dto.contract,
    job: dto.job,
    rythms: dto.rythms,
    service: dto.service,
    unit: dto.unit,
    additionalInformation: dto.additionalInformation,
    netRemuneration: dto.netRemuneration,
    grossRemuneration: dto.grossRemuneration,
    start: dto.start,
    end: dto.end,
    facility: mapFacilityDTOToFacility(dto.facility),
    lead: mapLeadDTOToLead(dto.lead),
    daySchedules: mapDayScheduleRequestDTOToDayScheduleRequest(dto.daySchedules),
    isSplittable: dto.isSplittable,
  };
};

const getRequestById = async (id: string, signal: GenericAbortSignal) => {
  const url = `/requests/${id}`;

  return httpService.get<RequestDTO>(url, { signal }).then((response) => {
    return mapRequestDTOToRequest(response.data);
  });
};

export default getRequestById;
