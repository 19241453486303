import { IonContent, IonPage } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import AuthHeader from '@carers/views/Auth/components/AuthHeader';
import BlankSlate from '@shared/components/BlankSlate/BlankSlate';

const AppErrorFallback = () => {
  const { t } = useTranslation('app', { keyPrefix: 'carers' });

  const reloadApp = () => {
    window.location.replace('/');
  };

  return (
    <IonPage>
      <AuthHeader />

      <IonContent>
        <BlankSlate
          action={reloadApp}
          actionName={t('common.actions.reloadPage')}
          message={t('common.messages.errors.generic')}
        />
      </IonContent>
    </IonPage>
  );
};

export default AppErrorFallback;
