type ListItem = string;
type List = ListItem[];

enum ListActionKind {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  TOGGLE = 'TOGGLE',
}

type ListAction = {
  type: ListActionKind;
  payload: ListItem;
};

function listReducer(state: List, action: ListAction): List {
  const { type, payload } = action;

  switch (type) {
    case ListActionKind.ADD:
      return [...state, payload];
    case ListActionKind.REMOVE:
      return [...state].filter((i) => i !== payload);
    case ListActionKind.TOGGLE:
      return listReducer(state, {
        type: state.includes(payload) ? ListActionKind.REMOVE : ListActionKind.ADD,
        payload,
      });
    default:
      return state;
  }
}

export { listReducer, ListActionKind, type List, type ListItem };
