import { InAppBrowser, UrlChangeListener } from '@capgo/inappbrowser';
import { isPlatform, useIonLoading } from '@ionic/react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import missionQueries from '@carers/views/Missions/utils/missionQueryFactory';
import { delay } from '@shared/utils/utils-fn';

const SUFFIX_SIGNING_OK = 'signingActionEnded';
const WAIT_SIGNATURE_DELAY = 20 * 1000;
const isMissionHasBeenSignedInTheWebview = (url: string) => url.includes(SUFFIX_SIGNING_OK);

const useSignContract = (signatureUrl?: string | null) => {
  const queryClient = useQueryClient();
  const [presentLoading, dismissLoading] = useIonLoading();
  const { t } = useTranslation('app', { keyPrefix: 'carers' });

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const handleChangeUrlEvent: UrlChangeListener = async (_event) => {
    if (isMissionHasBeenSignedInTheWebview(_event.url)) {
      // HACK present loading during some time, cause Mediflash BO has delay with getAccept hook
      await InAppBrowser.close();
      await presentLoading({ message: t('missions.detail.messages.infos') });
      await delay(WAIT_SIGNATURE_DELAY);
      await queryClient.invalidateQueries({ queryKey: missionQueries.all(), type: 'all' });
      await dismissLoading();
    }
  };

  const handleCloseEvent: UrlChangeListener = async () => {
    await InAppBrowser.removeAllListeners();
  };

  const initListeners = async () => {
    await InAppBrowser.addListener('urlChangeEvent', handleChangeUrlEvent);
    await InAppBrowser.addListener('closeEvent', handleCloseEvent);
  };

  const startSigningSequence = async () => {
    if (!signatureUrl) {
      throw new Error('No contractSignatureUrl set on the mission');
    }

    if (isPlatform('hybrid')) {
      await initListeners();
      setTimeout(async () => InAppBrowser.openWebView({ url: signatureUrl }), 100);
    } else {
      window.location.href = signatureUrl;
    }
  };

  return { startSigningSequence };
};

export default useSignContract;
