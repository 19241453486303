import { queryOptions, useQuery } from '@tanstack/react-query';
import { GenericAbortSignal } from 'axios';

import { httpService } from '@shared/httpService';

type AppConstantsDTO = {
  acceptedJobs: Record<string, string>;
  contracts: Record<string, string>;
  jobs: Record<string, string>;
  opsLead: Record<string, string>;
  scheduleRythms: Record<string, string>;
  services: Record<string, string>;
  statuses: Record<string, string>;
  tasks: Record<string, string>;
  unsubscriptionReasons: Record<string, string>;
  scheduleProblemReportReasons: Record<string, string>;
};

type AppConstants = {
  contracts: Record<string, string>;
  opsLead: Record<string, string>;
  scheduleRythms: Record<string, string>;
  tasks: Record<string, string>;
  unsubscriptionReasons: Record<string, string>;
  scheduleProblemReportReasons: Record<string, string>;
};

const mapAppConstantsDTOToAppConstant = (dto: AppConstantsDTO): AppConstants => {
  return {
    contracts: dto.contracts,
    opsLead: dto.opsLead,
    scheduleRythms: dto.scheduleRythms,
    tasks: dto.tasks,
    unsubscriptionReasons: dto.unsubscriptionReasons,
    scheduleProblemReportReasons: dto.scheduleProblemReportReasons,
  };
};

const fetchAppConstants = async (signal: GenericAbortSignal) => {
  const url = `/constants`;

  return httpService
    .get<AppConstantsDTO>(url, { signal })
    .then((response) => mapAppConstantsDTOToAppConstant(response.data));
};

const appConstantsQueryOptions = queryOptions({
  queryKey: ['appConstants'],
  queryFn: ({ signal }) => fetchAppConstants(signal),
  staleTime: Infinity,
  gcTime: Infinity,
});

const useGetAppConstants = () => useQuery(appConstantsQueryOptions);

export default useGetAppConstants;
