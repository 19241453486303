import { IonButton, IonContent, IonFooter, IonToolbar } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import { FEEDBACK_WIDGET_URL } from '@facilities/constants/retoolConstants';
import formatRetoolWidgetUrl from '@facilities/utils/utils-fn';
import styles from '@facilities/views/Home/Home.module.css';
import RetoolWidget from '@facilities/views/RetoolWidget/RetoolWidget';

type SendFeedbackHandleDataType = {
  shouldCloseSendFeedback: boolean;
};

type SidePanelParams = {
  facilityId: string;
  setShouldShowSidePanel: (bool: boolean) => void;
};

const SidePanel = ({ facilityId, setShouldShowSidePanel }: SidePanelParams) => {
  const { t } = useTranslation('app', { keyPrefix: 'facilities' });
  const feedbackWidgetUrl = formatRetoolWidgetUrl(FEEDBACK_WIDGET_URL, { facilityId });

  const hideSidePanel = () => {
    setShouldShowSidePanel(false);
  };

  const handleSendFeedback = ({ shouldCloseSendFeedback }: SendFeedbackHandleDataType) => {
    if (shouldCloseSendFeedback) {
      hideSidePanel();
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
      <IonContent>
        <div className={styles.planningContainer}>
          <RetoolWidget url={feedbackWidgetUrl} handleData={handleSendFeedback} />
        </div>
      </IonContent>

      <IonFooter>
        <IonToolbar color="light">
          <div slot="primary" className="stack ion-padding">
            <IonButton fill="outline" onClick={hideSidePanel}>
              {t('actions.cancel')}
            </IonButton>
          </div>
        </IonToolbar>
      </IonFooter>
    </div>
  );
};

export { type SendFeedbackHandleDataType, SidePanel };
