import { useMutation } from '@tanstack/react-query';

import useAuthState from '@carers/hooks/useAuthState';
import { httpService } from '@shared/httpService';
import { setCustomUserId } from '@shared/utils/Notifications';

const signOut = async () => {
  const url = '/auth/signout';

  return httpService.post<void>(url).then((response) => response.data);
};

const useSignOut = () => {
  const { clearAuth } = useAuthState();

  return useMutation({
    mutationFn: signOut,
    onSettled: (res) => {
      clearAuth();
      setCustomUserId(null);

      return res;
    },
  });
};

export default useSignOut;
