import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query';

import { RequestsFilters } from '@carers/views/Requests/models/RequestsFilters';
import getRequests from '@carers/views/Requests/services/fetchRequests';
import getRequestById from '@carers/views/Requests/services/getRequest';

const requestQueries = {
  all: () => ['requests'],

  lists: () => [...requestQueries.all(), 'list'],
  list: (filters: RequestsFilters) =>
    infiniteQueryOptions({
      queryKey: [...requestQueries.lists(), filters],
      queryFn: ({ pageParam, signal }) => getRequests(pageParam, filters, signal),
      initialPageParam: '',
      getNextPageParam: (lastPage) => lastPage.nextId,
      select: (data) => ({
        ...data,
        pages: data.pages.flatMap((page) => page.data),
      }),
    }),

  details: () => [...requestQueries.all(), 'detail'],
  detail: (id: string) =>
    queryOptions({
      queryKey: [...requestQueries.details(), id],
      queryFn: ({ signal }) => getRequestById(id, signal),
      gcTime: 5000,
    }),
};

export default requestQueries;
