import { useMemo } from 'react';

import CardMissionSkeleton from '@carers/components/CardMission/CardMissionSkeleton';
import { generateArrayOfRandomNumbers } from '@shared/utils/utils-fn';

type CardsMissionsSkeletonProps = {
  count?: number;
};

const CardsMissionsSkeleton = ({ count = 8 }: CardsMissionsSkeletonProps) => {
  const randomIds = useMemo(() => generateArrayOfRandomNumbers(count), [count]);

  return randomIds.map((item) => <CardMissionSkeleton key={item} />);
};

export default CardsMissionsSkeleton;
