import useFormatRemuneration from '@carers/hooks/useFormatRemuneration';
import { Mission } from '@carers/views/Missions/models/Missions';
import { Request } from '@carers/views/Requests/models/Requests';

const useCalculatePaymentRemuneration = (
  mission: Request | Mission,
  selectedDateSchedulesIds?: string[],
) => {
  const selectedDateSchedules = mission.daySchedules.filter((schedule) =>
    selectedDateSchedulesIds ? selectedDateSchedulesIds.includes(schedule.id) : null,
  );

  const cumulatedGrossRemuneration =
    selectedDateSchedules.reduce((sum, schedule) => sum + (schedule.grossRemuneration || 0), 0) ||
    null;

  const cumulatedNetRemuneration =
    selectedDateSchedules.reduce((sum, schedule) => sum + (schedule.netRemuneration || 0), 0) ||
    null;

  const cumulatedRemuneration = useFormatRemuneration(
    selectedDateSchedulesIds ? cumulatedGrossRemuneration : mission.grossRemuneration,
    selectedDateSchedulesIds ? cumulatedNetRemuneration : mission.netRemuneration,
  );

  return { cumulatedGrossRemuneration, cumulatedNetRemuneration, cumulatedRemuneration };
};

export default useCalculatePaymentRemuneration;
