import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import styles from './MissionFilterSegment.module.css';

type MissionSegmentProps = {
  onChange: (value: string) => void;
  value?: string;
};

const segmentButtons = [
  { label: 'toCome', value: 'false' },
  { label: 'past', value: 'true' },
];

const MissionFilterSegment = ({ onChange, value }: MissionSegmentProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.missions.list' });

  return (
    <IonSegment
      mode="ios"
      value={value ?? 'false'}
      className={styles.ionSegment}
      onIonChange={(e) => onChange(e.target.value as string)}
    >
      {segmentButtons.map((button) => (
        <IonSegmentButton value={button.value} key={button.value} className={styles.segmentButton}>
          <IonLabel>{t(button.label)}</IonLabel>
        </IonSegmentButton>
      ))}
    </IonSegment>
  );
};

export default MissionFilterSegment;
