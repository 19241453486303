import { IonRefresher, IonRefresherContent, RefresherEventDetail } from '@ionic/react';

type RefresherProps = {
  refetch: () => void;
};

const Refresher = ({ refetch }: RefresherProps) => {
  const handleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    await refetch();
    event.detail.complete();
  };

  return (
    <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
      <IonRefresherContent />
    </IonRefresher>
  );
};

export default Refresher;
