import { PropsWithChildren } from 'react';

import styles from './GridCards.module.css';

type GridCardsProps = PropsWithChildren;

const GridCards = ({ children }: GridCardsProps) => {
  return (
    <div className={styles.cardsContainer}>
      <div className={styles.cardsWrapper}>{children}</div>
    </div>
  );
};

export default GridCards;
