import { CARERS_MAIN_ROUTES } from '@carers/routes/routes';
import { FACILITIES_TABS_ROOT_ROUTES } from '@facilities/routes/routes';
import ROOT_ROUTES from '@shared/routes/rootRoutes';
import { AppRoute, RouteId } from '@shared/routes/route';

const APP_ROUTES: AppRoute<RouteId>[] = [
  ...ROOT_ROUTES,
  ...CARERS_MAIN_ROUTES,
  ...FACILITIES_TABS_ROOT_ROUTES,
];

const DEFAULT_MAIN_ROUTE = APP_ROUTES.find((r) => r.id === 'activity')!.path;
const DEFAULT_UNAUTHENTICATED_ROUTE = APP_ROUTES.find((r) => r.id === 'login')!.path;

export { APP_ROUTES, DEFAULT_MAIN_ROUTE, DEFAULT_UNAUTHENTICATED_ROUTE };
