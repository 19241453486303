import { GenericAbortSignal } from 'axios';

import { httpService } from '@shared/httpService';

type FacilityDTO = {
  id: string;
  name: string;
  calendarUrl: string;
  county: string;
};

type Facility = {
  name: string;
  calendarUrl: string;
  county: string;
};

const mapFacilityDTOToFacility = (dto: FacilityDTO): Facility => {
  return {
    name: dto.name,
    calendarUrl: dto.calendarUrl,
    county: dto.county,
  };
};

const { VITE_FACILITIES_MISSIONS_CALENDAR_OUTBOUND_API_KEY } = import.meta.env;

const getFacilityById = async (id: string, signal: GenericAbortSignal) => {
  const url = `/facilities/${id}`;

  const config = {
    headers: {
      'X-API-KEY': VITE_FACILITIES_MISSIONS_CALENDAR_OUTBOUND_API_KEY,
    },
    signal,
  };

  return httpService
    .get<FacilityDTO>(url, config)
    .then((response) => mapFacilityDTOToFacility(response.data));
};

export default getFacilityById;

export type { Facility };
