const enum CONTRACTS {
  INDEPENDENT = 'INDEPENDENT',
  SHORT_TERM = 'SHORT_TERM',
}

const enum SERVICES {
  HOME_NURSING_SERVICES = 'HOME_NURSING_SERVICES',
}

const enum RYTHMS {
  DAY = 'DAY',
  NIGHT = 'NIGHT',
}

export { CONTRACTS, RYTHMS, SERVICES };
