import { IonSpinner } from '@ionic/react';
import React, { useState } from 'react';

type AirtableCalendarIframeProps = {
  title: string;
  calendarUrl: string;
};

const AirtableCalendarIframe = ({ title, calendarUrl }: AirtableCalendarIframeProps) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const handleLoad = (): void => {
    setIsLoaded(true);
  };

  return (
    <>
      <iframe
        title={title}
        className="ion-padding"
        style={{ width: '100%', height: '100%' }}
        onLoad={handleLoad}
        src={calendarUrl}
        frameBorder="0"
      />
      {!isLoaded ? <IonSpinner /> : null}
    </>
  );
};

export default AirtableCalendarIframe;
