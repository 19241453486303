import { useIonRouter } from '@ionic/react';
import { useMutation } from '@tanstack/react-query';

import { AuthMode } from '@carers/views/Auth/AuthMode';
import {
  AxiosErrorWithMessage,
  ResponseErrorDTO,
} from '@shared/HttpInterceptors/GenericAxiosResponseError';
import { CustomAxiosRequestConfig, httpService } from '@shared/httpService';

type RequestSigninParams = { mode: AuthMode; value: string };
type ResponseErrorOTPDTO = { error: { nextRetryAllowedAt: string } } & ResponseErrorDTO;

const TOO_MANY_SIGNIN_CODE_REQUESTS = 429;

const requestSigninCode = async ({ mode, value }: RequestSigninParams) => {
  const validationBaseMessage = 'auth.login.messages.errors';
  const REQUEST_SIGNIN: Record<
    AuthMode,
    { url: string; key: string; validationErrorMessage?: string }
  > = {
    [AuthMode.SMS]: {
      url: 'sms',
      key: 'phone',
      validationErrorMessage: `${validationBaseMessage}.invalidSmsEntry`,
    },
    [AuthMode.EMAIL]: {
      url: 'email',
      key: 'email',
      validationErrorMessage: `${validationBaseMessage}.invalidEmailEntry`,
    },
  } as const;

  const url = `/auth/request-signin-code/${REQUEST_SIGNIN[mode].url}`;
  const config: CustomAxiosRequestConfig = {
    validationErrorMessage: REQUEST_SIGNIN[mode].validationErrorMessage,
  };

  return httpService
    .post<{ nextRetryAllowedAt: string }>(url, { [REQUEST_SIGNIN[mode].key]: value }, config)
    .then((response) => new Date(response.data.nextRetryAllowedAt))
    .catch((err: AxiosErrorWithMessage<ResponseErrorOTPDTO>) => {
      return err.response?.status === TOO_MANY_SIGNIN_CODE_REQUESTS
        ? new Date(err.response.data.error.nextRetryAllowedAt)
        : Promise.reject(err);
    });
};

const useRequestSigninCode = () => {
  const router = useIonRouter();

  return useMutation({
    mutationFn: requestSigninCode,
    onSuccess: (data, variables) => {
      const { mode: authMode, value } = variables;

      const params = new URLSearchParams([[authMode, value]]);
      router.push(`/auth/otp-code?${params.toString()}`);
    },
  });
};

const useRequestSigninCodeOnOTP = () => {
  const mutation = useMutation({
    mutationFn: requestSigninCode,
  });

  return mutation;
};

export { useRequestSigninCode, useRequestSigninCodeOnOTP };
