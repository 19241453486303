enum ANALYTICS_LOG_EVENTS {
  NEW_REQUEST_REDIRECT_WIDGET_CLICK = 'new_request_redirect_widget_click',
  TASKS_REDIRECT_WIDGET_CLICK = 'tasks_redirect_widget_click',
  AUTH_FAILURE = 'facility_auth_failure',
  CONFIRM_TASK_CLICK = 'confirm_task_click',
  REDIRECT_TO_NEW_REQUEST_FROM_CARERS_PAGE_CLICK = 'redirect_to_new_request_from_carers_page_click',
  NEW_REQUEST_CREATED_AFTER_REDIRECT_FROM_CARERS_PAGE = 'new_request_created_after_redirect_from_carers_page',
}

// eslint-disable-next-line import/prefer-default-export
export { ANALYTICS_LOG_EVENTS };
