import { IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import styles from '@facilities/views/containers.module.css';
import ChatIcon from '@shared/assets/icons/chat.svg';
import useIsScreenXL from '@shared/hooks/useIsScreenXL';

type FeedbackButtonParams = {
  setShouldShowSidePanel: (bool: boolean) => void;
};

const FeedbackButton = ({ setShouldShowSidePanel }: FeedbackButtonParams) => {
  const { t } = useTranslation('app', { keyPrefix: 'facilities' });

  const isScreenXL = useIsScreenXL();

  const showSidePanel = () => {
    setShouldShowSidePanel(true);
  };

  return (
    isScreenXL && (
      <IonFab slot="end" vertical="bottom" horizontal="end">
        <IonFabButton className={`${styles.sendFeedbackButton}`} onClick={showSidePanel}>
          <IonIcon icon={ChatIcon} />
          {t('actions.sendFeedback')}
        </IonFabButton>
      </IonFab>
    )
  );
};

export default FeedbackButton;
