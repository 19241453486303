import { GenericAbortSignal } from 'axios';

import { httpService } from '@shared/httpService';

type UserTasksDTO = {
  pendingContractSignatureMissionsCount: number;
  pendingSchedulesValidationMissionsCount: number;
};

type UserTasks = {
  pendingContractSignatureMissionsCount: number;
  pendingSchedulesValidationMissionsCount: number;
};

const mapUserTasksDTOtoUserTask = (dto: UserTasksDTO): UserTasks => {
  return { ...dto };
};

const getUserTasksCount = async (signal: GenericAbortSignal) => {
  const url = `/tasks`;

  return httpService.get<UserTasksDTO>(url, { signal }).then((response) => {
    return mapUserTasksDTOtoUserTask(response.data);
  });
};

export default getUserTasksCount;
