import { HTTPErrorCode } from '@shared/HttpInterceptors/HTTP_ERROR_CODES';

const REQUEST_UNAVAILABLE_ERROR_CODES = [
  HTTPErrorCode.REQUEST_ALREADY_HAS_A_POSITIONED_CARER_ON_SOME_SCHEDULES,
  HTTPErrorCode.REQUEST_ALREADY_ACCEPTED,
  HTTPErrorCode.REQUEST_ALREADY_FULFILLED,
];

const ERROR_HAPPENED = [
  HTTPErrorCode.ACCEPTED_SCHEDULE_DOES_NOT_EXIST_ON_REQUEST,
  HTTPErrorCode.REQUEST_IS_NOT_VISIBLE_ON_CARER_APP,
  HTTPErrorCode.REQUEST_SHOULD_HAVE_ACTIVE_POSITIONED_CARER,
  HTTPErrorCode.REQUEST_NOT_SPLITTABLE,
];

const isRequestUnavailable = (errorCode?: HTTPErrorCode) =>
  errorCode && REQUEST_UNAVAILABLE_ERROR_CODES.includes(errorCode);

const isRequestCancelled = (errorCode?: HTTPErrorCode) =>
  errorCode && errorCode === HTTPErrorCode.CANCELLED_REQUEST_CANNOT_BE_ACCEPTED_BY_CARER;

const isRequestUnableToValidate = (errorCode?: HTTPErrorCode) =>
  errorCode && ERROR_HAPPENED.includes(errorCode);

export { isRequestUnableToValidate, isRequestUnavailable, isRequestCancelled };
