import { AuthMode } from '@carers/views/Auth/AuthMode';
import phoneIcon from '@shared/assets/icons/local_phone.svg';
import mailIcon from '@shared/assets/icons/mail_outline.svg';

type LoginModeInput = {
  name: AuthMode;
  label: string;
  icon: string;
  info: string;
  switchText: string;
  inputType: 'tel' | 'email';
  otherType: AuthMode;
};

const LoginModeInput: Record<AuthMode, LoginModeInput> = {
  sms: {
    name: AuthMode.SMS,
    label: 'phoneNumber',
    icon: phoneIcon,
    info: 'infoSMS',
    switchText: 'actions.preferCodeByEmail',
    inputType: 'tel',
    otherType: AuthMode.EMAIL,
  },
  email: {
    name: AuthMode.EMAIL,
    label: 'emailAddress',
    icon: mailIcon,
    info: 'infoEmail',
    switchText: 'actions.preferCodeBySms',
    inputType: 'email',
    otherType: AuthMode.SMS,
  },
};

export default LoginModeInput;
