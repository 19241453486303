import { IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import { DATE_CONFIG, useFormatDate } from '@carers/hooks/useFormatDate';
import MissionPropertyCardScaffold from '@carers/views/Missions/MissionDetail/components/Cards/MissionPropertyCardScaffold';
import { Mission } from '@carers/views/Missions/models/Missions';
import EditIcon from '@shared/assets/icons/edit.svg';

import styles from '../../MissionDetail.module.css';

type MissionPaymentCardProps = {
  mission: Mission;
};

const MissionContractCard = ({ mission }: MissionPaymentCardProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.missions.detail' });
  const { formatDate } = useFormatDate();

  let content: JSX.Element | null = null;

  if (mission.contractSignedAt === null) {
    content = <IonLabel color="primary">{t('contractNotSign')}</IonLabel>;
  } else {
    content = (
      <>
        <IonLabel color="primary">{t('contractSignOn')}</IonLabel>
        <IonLabel color="primary" className={styles.typoDate}>
          {mission.contractSignedAt
            ? formatDate(mission.contractSignedAt, DATE_CONFIG.FULL_DATE_WITH_TIME)
            : null}
        </IonLabel>
      </>
    );
  }

  return (
    <MissionPropertyCardScaffold title={t('contract')} icon={EditIcon}>
      {content}
    </MissionPropertyCardScaffold>
  );
};

export default MissionContractCard;
