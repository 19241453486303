import Retool from 'react-retool';

type RetoolWidgetProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleData?: (data?: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  url: string;
};

const RetoolWidget = ({ data, handleData, url }: RetoolWidgetProps) => {
  return <Retool data={{ data }} onData={handleData} url={url} />;
};

export default RetoolWidget;
