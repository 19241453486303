import {
  IonButton,
  IonCard,
  IonCardContent,
  IonContent,
  IonFooter,
  IonLabel,
  IonSkeletonText,
  IonToolbar,
} from '@ionic/react';
import React from 'react';

import CardDetailSkeleton from '@carers/components/CardDetail/CardDetailSkeleton/CardDetailSkeleton';
import { ModalTitle } from '@carers/components/Modal';

const RequestAcceptanceSkeleton = () => {
  return (
    <>
      <IonContent className="ion-padding">
        <ModalTitle id="acceptance-modal-title">
          <IonSkeletonText style={{ width: '200px' }} animated />
        </ModalTitle>

        <form id="acceptanceRequestsForm" className="ion-padding-top stack">
          <IonCard>
            <IonCardContent>
              <IonSkeletonText style={{ height: '200px', width: '100%' }} animated />
            </IonCardContent>
          </IonCard>
          <CardDetailSkeleton />
          <IonLabel>
            <IonSkeletonText animated />
          </IonLabel>
        </form>
      </IonContent>

      <IonFooter>
        <IonToolbar color="light">
          <div slot="primary" className="stack ion-padding">
            <IonButton fill="outline" expand="block" disabled>
              <IonSkeletonText animated />
            </IonButton>

            <IonButton expand="block" disabled>
              <IonSkeletonText animated />
            </IonButton>
          </div>
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export default RequestAcceptanceSkeleton;
