import { useMutation } from '@tanstack/react-query';

import useAuthState from '@carers/hooks/useAuthState';
import useRedirectPathAfterSignIn from '@carers/hooks/useRedirectPathAfterSignIn';
import { ANALYTICS_LOG_EVENTS } from '@carers/utils/Analytics.ts';
import { AuthMode } from '@carers/views/Auth/AuthMode';
import getUserId from '@carers/views/Auth/OTPCode/utils/auth';
import { httpService } from '@shared/httpService';
import { logEvent } from '@shared/utils/Analytics';
import { setCustomUserId } from '@shared/utils/Notifications';

type UserSignInDTO = {
  accessToken: string;
  refreshToken: string;
};

type RequestSigninParams = { mode: AuthMode; value: string; code: string };

const signIn = async ({ mode, value, code }: RequestSigninParams) => {
  const URL_AUTH_MODE: Record<AuthMode, string> = {
    [AuthMode.SMS]: 'phone',
    [AuthMode.EMAIL]: 'email',
  };

  const url = `/auth/signin/${URL_AUTH_MODE[mode]}`;

  return httpService
    .post<UserSignInDTO>(url, {
      [URL_AUTH_MODE[mode]]: value,
      code,
    })
    .then((response) => response.data);
};

const useSignIn = () => {
  const { setAuth } = useAuthState();
  const { redirectAfterSignIn } = useRedirectPathAfterSignIn();

  return useMutation({
    mutationFn: signIn,
    onSuccess: async (res) => {
      setAuth(res);
      redirectAfterSignIn();

      const userId = getUserId(res.accessToken);
      setCustomUserId(userId);

      void logEvent(ANALYTICS_LOG_EVENTS.AUTH_SUCCESS);
    },
    onError: async () => {
      void logEvent(ANALYTICS_LOG_EVENTS.AUTH_FAILURE);
    },
  });
};

export default useSignIn;
