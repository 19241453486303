import { IonButton, IonFooter, IonToolbar } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import styles from '../../MissionDetail.module.css';

type FooterProps = {
  shouldShowSignContractButton: boolean;
  shouldShowValidateSchedulesButton: boolean;
  shouldShowReportSchedulesButton: boolean;
  startSigningSequence: () => void;
};

const MobileFooter = ({
  shouldShowSignContractButton,
  shouldShowValidateSchedulesButton,
  shouldShowReportSchedulesButton,
  startSigningSequence,
}: FooterProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.missions.detail' });

  return (
    <IonFooter>
      <IonToolbar color="light" className={styles.safeArea}>
        <div slot="primary" className="stack ion-padding">
          {shouldShowReportSchedulesButton && (
            <IonButton expand="block" fill="outline" id="open-report-modal">
              {t('actions.reportProblem')}
            </IonButton>
          )}
          {shouldShowValidateSchedulesButton && (
            <IonButton expand="block" id="open-validate-modal">
              {t('actions.validateSchedules')}
            </IonButton>
          )}
          {shouldShowSignContractButton && (
            <IonButton expand="block" onClick={startSigningSequence}>
              {t('actions.signContract')}
            </IonButton>
          )}
        </div>
      </IonToolbar>
    </IonFooter>
  );
};

export default MobileFooter;
