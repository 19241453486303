import { queryOptions } from '@tanstack/react-query';

import fetchUserAccount from '@carers/views/Profile/services/FetchUserAccount';

const userAccountQueries = {
  all: () => ['userAccount'],

  detail: () =>
    queryOptions({
      queryKey: [...userAccountQueries.all(), 'detail'],
      queryFn: ({ signal }) => fetchUserAccount(signal),
    }),
};

export default userAccountQueries;
