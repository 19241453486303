import type {} from '@batch.com/cordova-plugin';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useAuthState from '@carers/hooks/useAuthState';
import { init } from '@shared/utils/Notifications';

const BatchSDK = () => {
  const { t } = useTranslation('app');

  const { accessToken } = useAuthState();

  const currentPath = window.location.href;

  const isFacilitiesAppPage = currentPath.includes('facilities');
  const webNotificationModalContent = !isFacilitiesAppPage
    ? {
        text: t('carers.common.messages.notifications.activateNotificationText'),
        positiveSubBtnLabel: t(
          'carers.common.messages.notifications.confirmActivateNotificationsButtonLabel',
        ),
        negativeBtnLabel: t(
          'carers.common.messages.notifications.declineActivateNotificationsButtonLabel',
        ),
      }
    : {
        text: t('facilities.common.messages.notifications.activateNotificationText'),
        positiveSubBtnLabel: t(
          'facilities.common.messages.notifications.confirmActivateNotificationsButtonLabel',
        ),
        negativeBtnLabel: t(
          'facilities.common.messages.notifications.declineActivateNotificationsButtonLabel',
        ),
      };

  useEffect(() => {
    init(webNotificationModalContent, accessToken);
  }, []);

  return null;
};

export default BatchSDK;
