import { queryOptions } from '@tanstack/react-query';

import getFacilityById from '@facilities/services/GetFacility';

const facilityQueries = {
  all: () => ['facility'],
  detail: (id: string) =>
    queryOptions({
      queryKey: [...facilityQueries.all(), id],
      queryFn: ({ signal }) => getFacilityById(id, signal),
      gcTime: 5000,
    }),
};

export default facilityQueries;
