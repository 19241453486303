import { useTranslation } from 'react-i18next';

const secondsToDuration = (durationInSeconds: number) => {
  return {
    day: Math.floor(durationInSeconds / 86_400),
    hour: Math.floor(durationInSeconds / 3_600) % 24,
    minute: Math.floor(durationInSeconds / 60) % 60,
    second: Math.floor(durationInSeconds) % 60,
  };
};

const useDurationTime = (locale?: string) => {
  const { i18n } = useTranslation('app', { keyPrefix: 'carers' });
  const language = locale ?? i18n.language;

  const formatDuration = (durationInSeconds: number) => {
    const duration = secondsToDuration(durationInSeconds);
    const durationsEntrie = Object.entries(duration).filter((val) => val[1] !== 0);
    const isPlural = durationsEntrie[0][1] > 1;

    const durations = durationsEntrie.map(([key, val]) => {
      return new Intl.NumberFormat(language, {
        style: 'unit',
        unitDisplay: 'narrow',
        unit: key,
      }).format(val);
    });

    return {
      value: new Intl.ListFormat(language, {
        style: 'short',
        type: 'conjunction',
      }).format(durations),
      isPlural,
    };
  };

  return formatDuration;
};

export default useDurationTime;
