import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query';

import { getMissionsByMonth } from '@carers/views/Activity/services/getMissionsByMonth';
import getUserTasksCount from '@carers/views/Activity/services/getUserTasksCount';
import { MissionsFilters } from '@carers/views/Missions/models/MissionsFilters';
import getMissionById from '@carers/views/Missions/services/getMissionDetail';
import getMissions from '@carers/views/Missions/services/getMissions';

const missionQueries = {
  all: () => ['missions'],

  lists: () => [...missionQueries.all(), 'list'],
  list: (filters: MissionsFilters) =>
    infiniteQueryOptions({
      queryKey: [...missionQueries.lists(), filters],
      queryFn: ({ pageParam, signal }) => getMissions(pageParam, filters, signal),
      initialPageParam: '',
      getNextPageParam: (lastPage) => lastPage.nextId,
      select: (data) => ({
        ...data,
        pages: data.pages.flatMap((page) => page.data),
      }),
    }),

  details: () => [...missionQueries.all(), 'detail'],
  detail: (id: string) =>
    queryOptions({
      queryKey: [...missionQueries.details(), id],
      queryFn: ({ signal }) => getMissionById(id, signal),
      gcTime: 5000,
    }),

  byMonth: (date: string) =>
    queryOptions({
      queryKey: [...missionQueries.all(), 'by-months', date],
      queryFn: ({ signal }) => getMissionsByMonth(date, signal),
      staleTime: 2 * 60 * 1000,
      gcTime: 2 * 60 * 1000,
    }),

  tasksCount: () =>
    queryOptions({
      queryKey: [...missionQueries.all(), 'tasksCount'],
      queryFn: ({ signal }) => getUserTasksCount(signal),
    }),
};

export default missionQueries;
