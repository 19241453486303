import { AuthMode } from '@carers/views/Auth/AuthMode';

type OTPTypeCodeUnitType = {
  name: string;
  infoCode: string;
  title: string;
  checkLoginMode: string;
  notReceived: string;
  switchText: string;
  otherType: AuthMode;
};

const OTPTypeCode: Record<AuthMode, OTPTypeCodeUnitType> = {
  sms: {
    name: 'codeNotReceivedModal.sms',
    infoCode: 'infoCodeSMS',
    title: 'SMSNotReceived',
    checkLoginMode: 'codeNotReceivedModal.number',
    notReceived: 'SMSNotReceived',
    switchText: 'actions.preferCodeByEmail',
    otherType: AuthMode.EMAIL,
  },
  email: {
    name: 'codeNotReceivedModal.mail',
    title: 'EmailNotReceived',
    checkLoginMode: 'codeNotReceivedModal.mail',
    infoCode: 'infoCodeEmail',
    notReceived: 'EmailNotReceived',
    switchText: 'actions.preferCodeBySms',
    otherType: AuthMode.SMS,
  },
};

export { OTPTypeCode, type OTPTypeCodeUnitType };
