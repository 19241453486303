import { AxiosError } from 'axios';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import DefaultErrorImage from '@shared/assets/images/status/generic-error.svg';
import NetworkErrorImage from '@shared/assets/images/status/network-error.svg';
import NotFoundImage from '@shared/assets/images/status/not-found.svg';
import BlankSlate from '@shared/components/BlankSlate/BlankSlate';
import { GenericAxiosResponseError } from '@shared/HttpInterceptors/GenericAxiosResponseError';
import { HTTPErrorCode } from '@shared/HttpInterceptors/HTTP_ERROR_CODES';

type BlankSlateErrorProps = {
  error: GenericAxiosResponseError;
  action?: () => void;
  actionName?: string;
};

const ERROR_IMAGES = [
  HTTPErrorCode.UNSUBSCRIBED_CARER,
  HTTPErrorCode.CARER_WITH_PENDING_CANCELLATION_JUSTIFICATION,
  HTTPErrorCode.CARER_WITH_JOB_NOT_COVERED,
];

const getImageError = (error: GenericAxiosResponseError) => {
  if (error.code === AxiosError.ERR_NETWORK) {
    return NetworkErrorImage;
  }

  if (error.response?.data.error?.code && ERROR_IMAGES.includes(error.response.data.error.code)) {
    return NotFoundImage;
  }

  return DefaultErrorImage;
};

const BlankSlateError = ({
  error,
  action,
  actionName,
  children,
}: PropsWithChildren<BlankSlateErrorProps>) => {
  const { t } = useTranslation();

  const image = getImageError(error);

  const getErrorDetail = () => {
    const wasRequestNotFulfilled =
      error.response?.data.error?.code ===
      HTTPErrorCode.REQUEST_END_DATE_SHOULD_BE_ON_OR_AFTER_TODAY;

    const detail = wasRequestNotFulfilled
      ? {
          title: t(
            'carers.requests.detail.messages.errors.requestEndDateShouldBeOnOrAfterToday.title',
          ),
          message: t(
            'carers.requests.detail.messages.errors.requestEndDateShouldBeOnOrAfterToday.message',
          ),
        }
      : undefined;

    return detail;
  };

  const detail = getErrorDetail();

  return (
    <BlankSlate
      message={error.errorMessage}
      image={image}
      action={action}
      actionName={actionName}
      detail={detail}
    >
      {children}
    </BlankSlate>
  );
};

export default BlankSlateError;
