import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonFooter,
  IonToolbar,
} from '@ionic/react';
import dayjs from 'dayjs';
import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import CardFilterList from '@carers/components/CardFilterList/CardFilterList';
import { ModalTitle } from '@carers/components/Modal';
import useGenericFilters from '@carers/hooks/useGenericFilters';
import useGetAppConstants from '@carers/hooks/useGetAppConstants';
import { ANALYTICS_LOG_EVENTS } from '@carers/utils/Analytics.ts';
import {
  MISSIONS_FILTER_KEYS,
  MissionsSideFilters,
} from '@carers/views/Missions/models/MissionsFilters';
import BlankSlate from '@shared/components/BlankSlate/BlankSlate';
import { logEvent } from '@shared/utils/Analytics';

const Calendar = lazy(() => import('@carers/components/Calendar/Calendar'));

type MissionsFilterProps = {
  appliedFilters: MissionsSideFilters;
  onSubmit: (filters: MissionsSideFilters) => void;
};

const MissionsFilter = ({ appliedFilters, onSubmit }: MissionsFilterProps) => {
  const { t } = useTranslation();
  const { filters, resetFilters, updateFilters } =
    useGenericFilters<MissionsSideFilters>(appliedFilters);
  const { data: appConstants } = useGetAppConstants();

  const onSubmitFilter = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    void logEvent(ANALYTICS_LOG_EVENTS.MISSIONS_FILTERS_CLICK);
    onSubmit(filters);
  };

  return (
    <>
      <IonContent className="ion-padding">
        <ModalTitle id="filter-modal-title">{t('carers.common.filter.title')}</ModalTitle>

        <form
          onSubmit={onSubmitFilter}
          id="filterMissionsForm"
          className="ion-padding-top stack stack-large"
        >
          <CardFilterList
            title={t('carers.common.filter.actionToCarryOut')}
            filtersList={appConstants?.tasks ?? {}}
            activeKeys={filters.tasks}
            handleFilterChange={(data) => updateFilters({ [MISSIONS_FILTER_KEYS.TASKS]: data })}
          />
          <CardFilterList
            title={t('carers.common.filter.missionType')}
            filtersList={appConstants?.contracts ?? {}}
            activeKeys={filters.contracts}
            handleFilterChange={(data) => updateFilters({ [MISSIONS_FILTER_KEYS.CONTRACTS]: data })}
          />
          <CardFilterList
            title={t('carers.common.filter.missionRythm')}
            filtersList={appConstants?.scheduleRythms ?? {}}
            activeKeys={filters.rythms}
            handleFilterChange={(data) => updateFilters({ [MISSIONS_FILTER_KEYS.RYTHMS]: data })}
          />
          <IonCard>
            <IonCardHeader>
              <h5 className="ion-no-margin">{t('carers.common.filter.from')}</h5>
            </IonCardHeader>
            <IonCardContent>
              <Suspense fallback={<BlankSlate message={t('shared.messages.info.loading')} />}>
                <Calendar
                  selectedDay={
                    filters.withScheduleOnOrAfter ? dayjs(filters.withScheduleOnOrAfter) : null
                  }
                  minDate={dayjs()}
                  onSelectedDayChange={(date) =>
                    updateFilters({
                      [MISSIONS_FILTER_KEYS.WITH_SCHEDULE_ON_OR_AFTER]: date.format('YYYY-MM-DD'),
                    })
                  }
                />
              </Suspense>
            </IonCardContent>
          </IonCard>
        </form>
      </IonContent>
      <IonFooter>
        <IonToolbar color="light">
          <div slot="primary" className="stack ion-padding">
            <IonButton fill="outline" expand="block" onClick={resetFilters}>
              {t('carers.common.filter.actions.unselectFilters')}
            </IonButton>
            <IonButton expand="block" type="submit" form="filterMissionsForm">
              {t('carers.common.filter.actions.applyFilter')}
            </IonButton>
          </div>
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export default MissionsFilter;
