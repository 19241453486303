import { IonCard, IonCardContent, IonIcon, IonLabel } from '@ionic/react';
import React, { PropsWithChildren } from 'react';

import DangerIcon from '@shared/assets/icons/error_outline.svg';
import InfoIcon from '@shared/assets/icons/info.svg';
import WarningIcon from '@shared/assets/icons/warning.svg';

import styles from './Alert.module.css';

type AlertProps = {
  type: 'info' | 'warning' | 'danger';
  title?: string;
};

const iconMap: Record<AlertProps['type'], string> = {
  info: InfoIcon,
  warning: WarningIcon,
  danger: DangerIcon,
};

const Alert = ({ type, title, children }: PropsWithChildren<AlertProps>) => {
  const icon = iconMap[type];

  return (
    <IonCard className={`${styles.alertCard} ${styles[type]}`}>
      <IonCardContent className={`stack stack-horizontal ${styles.contentAlert}`}>
        <IonIcon icon={icon} className={styles.iconAlert} />

        <div className="stack stack-no-space">
          <IonLabel className={styles.title}>{title}</IonLabel>
          {children}
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export type { AlertProps };

export default Alert;
